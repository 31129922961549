import { FC } from "react";
import { ButtonStyled } from "./styled";
import { useTheme } from "@emotion/react";

interface ButtonProps {
  autoFocus?: boolean;
  className?: string;
  type?: "button" | "submit" | "reset";
  disabled?: boolean;
  onClick?: (event: React.MouseEvent<HTMLButtonElement>) => void;
  testId?: string;
  variant?:
    | "primary"
    | "secondary"
    | "outlined"
    | "icon"
    | "inline-icon"
    | "inline-outlined-icon"
    | "underline"
    | "text"
    | "quiet";
  weight?: "normal" | "bold";
  tabIndex?: number;
  size?: "small" | "medium";
}

export const Button: FC<ButtonProps> = ({
  autoFocus = false,
  className = "",
  type = "button",
  disabled = false,
  onClick,
  testId,
  children,
  variant = "primary",
  weight,
  tabIndex,
  size = "medium",
}) => {
  const theme = useTheme();

  return (
    <ButtonStyled
      onClick={onClick}
      type={type}
      disabled={disabled}
      data-variant={variant}
      data-size={size}
      data-weight={weight}
      data-testid={testId}
      className={className}
      tabIndex={tabIndex}
      autoFocus={autoFocus}
      theme={theme}
    >
      {children}
    </ButtonStyled>
  );
};

export default Button;
