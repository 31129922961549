interface JwtExtRole {
  /**
   * Merchant UUID
   */
  resource_id: string;
  /**
   * User role for that merchant.
   */
  role: string;
}

interface JwtExtMetadata {
  consent: string;
  test_mode: boolean;
}

export interface JwtPayload {
  aud: string[];
  client_id: string;
  exp: number;
  ext: {
    metadata: JwtExtMetadata;
    roles: JwtExtRole[];
  };
  iat: number;
  iss: string;
  jti: string;
  nbf: number;
  scp: string[];
  sub: string;
}

/**
 * Decodes JWT token payload
 * @param token JWT token
 */
export const decodeJwt = (token: string): JwtPayload => {
  const [, base64urlPayload] = token.split(".");

  // base64url encoding doesn't use + and /
  const base64Payload = base64urlPayload.replace("-", "+").replace("_", "/");

  const jsonPayload = atob(base64Payload);

  const payload = JSON.parse(jsonPayload) as JwtPayload;
  return payload;
};
