import React, { FC } from "react";
import Button from "../../atoms/Button";
import {
  TabCopyChildrenWrapper,
  HelpWrapper,
  OptionalTabLink,
  CopyWrapper,
  OutlinedInfo,
} from "./styled";
import { Trans } from "../../../util/i18n";
import { CurrencyResponse } from "../../../../tapper-browser-client";

interface TabCopyChildrenProps {
  amount: number;
  limit: number;
  currency: CurrencyResponse;
  isLoggedIn: boolean;
  isInfo?: boolean;
  onShowInfo?: () => void;
  onShowTabDetails?: () => void;
}

export const TabLink = ({
  children,
  onClick,
}: {
  children?: React.ReactNode;
  onClick?: () => void;
}) =>
  onClick ? (
    <OptionalTabLink onClick={onClick}>{children}</OptionalTabLink>
  ) : (
    <>{children}</>
  );

const DefaultTabCopyChildren: FC<TabCopyChildrenProps> = ({
  onShowInfo,
  onShowTabDetails,
  amount,
  limit,
  currency,
  isLoggedIn,
  isInfo = false,
}) => (
  <TabCopyChildrenWrapper marginTop={isLoggedIn && !isInfo}>
    <CopyWrapper isInfo={isInfo}>
      {!isLoggedIn ? (
        <Trans i18nKey="tabMakesItEasy" values={{ limit, currency }} />
      ) : (
        <Trans
          i18nKey={
            isInfo ? "youUsedAmountOfLimitOneLine" : "youUsedAmountOfLimit"
          }
          values={{ limit, amount, currency }}
          components={[<TabLink key="link" onClick={onShowTabDetails} />]}
        />
      )}
      {isInfo && (
        <button onClick={onShowInfo} aria-label="Show info">
          <OutlinedInfo>?</OutlinedInfo>
        </button>
      )}
    </CopyWrapper>
    {!isInfo && (
      <HelpWrapper>
        <Trans i18nKey="whatIsTab" parent="p" />
        <Button
          onClick={onShowInfo}
          variant="inline-icon"
          testId="show-info-button"
          aria-label="Show info"
        >
          ?
        </Button>
      </HelpWrapper>
    )}
  </TabCopyChildrenWrapper>
);

export default DefaultTabCopyChildren;
