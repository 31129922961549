import styled from "@emotion/styled";
import { OverflowHidden } from "../../../styles/GlobalStyles";

export const Container = styled.div`
  ${OverflowHidden}
  &[data-justify-end] {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
  }
`;
