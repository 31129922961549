import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Colors,
  TextMedium,
  TextSmall,
  TextTiny,
} from "../../../styles/GlobalStyles";
import Button from "../../atoms/Button";

const ButtonStyle = css`
  min-width: 206px;
  max-width: 100%;
  margin: auto;
  &[data-size="small"] {
    padding: 17px 16px;
  }
`;

export const WidgetTitle = styled.span`
  ${TextMedium}
  color: ${Colors.primary};
`;

export const TitleCopy = styled.div`
  ${TextMedium}
  color: ${Colors.primary};
  line-height: 20px;
  font-weight: 500;
`;

export const PayNowButton = styled(Button)`
  ${ButtonStyle}
  &[data-size="small"] {
    font-size: 16px;
  }
`;

export const MoreAccessButton = styled(Button)`
  ${ButtonStyle}
  margin-top: 16px;
`;

export const ViewTabButton = styled(Button)`
  ${ButtonStyle}
  color: ${Colors.primary};
  background: ${Colors.white};
  &:active,
  &:focus {
    color: ${Colors.primary};
  }
  filter: drop-shadow(0px 1px 1px rgba(0, 0, 0, 0.25));
`;

export const ErrorContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
  margin: 24px 0 0;
  p {
    ${TextSmall};
  }
`;

export const ErrorContactMessage = styled.div`
  ${TextTiny}
  font-weight: 300;
  a {
    text-decoration: underline;
  }
`;
