import styled from "@emotion/styled";
import { Colors, FlexCol, ScreenSizes } from "../../styles/GlobalStyles";
import { animated } from "react-spring";

export const BackDrop = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2147483647;
  background: ${Colors.primary};
  opacity: 0.6;
`;

export const AnimatedContainer = styled(animated.div)`
  position: fixed;
  z-index: 2147483647;
  bottom: 0;
  ${FlexCol}
  align-items: center;
  width: 100vw;
  font-size: 16px;

  @media (min-width: ${ScreenSizes.sm}) {
    height: 100%;
    justify-content: center;
  }

  @media (min-width: ${ScreenSizes.md}) {
    top: 0;
    height: 100vh;
  }
`;
