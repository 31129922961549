import React, { FC } from "react";
import {
  WidgetContainer,
  WidgetTriggerContainer,
  FooterWrapper,
  LoadingContainer,
  WidgetBackDrop,
  ThankYouContainer,
} from "./styled";
import LoggedOut from "./LoggedOut/index";
import Overview, { OverviewState } from "./Overview/index";
import DefaultFooter from "../atoms/DefaultFooter";
import WidgetTrigger from "./WidgetTrigger";
import { GlobalStyles } from "../../styles/GlobalStyles";
import { useActor } from "@xstate/react";
import { LeaveBehindMachineService } from "../../statecharts/leavebehindWidget/types";
import { Trans, useTranslation } from "react-i18next";

interface Props {
  service: LeaveBehindMachineService;
}

const LeaveBehindWidget: FC<Props> = ({ service }) => {
  const { t } = useTranslation();
  const [state, send] = useActor(service);
  const isMobile = Math.min(window.screen.width, window.screen.height) < 420;
  const accessTotalTime = state.context.userAccessDetails.length
    ? state.context.userAccessDetails[0].validTo -
      state.context.userAccessDetails[0].createdAt
    : 0;
  const isPaymentDone = !state.context.tab;
  const isSubscription = !!state.context.userAccessDetails[0]?.subscriptionId;

  const timeDelta =
    state.context.userAccessDetails.length &&
    t("formattedTimeDelta", {
      value: state.context.userAccessDetails[0]?.validTimedelta,
    });

  const onLogin = !state.context.userAuthorized
    ? () => send({ type: "LOG_IN" })
    : undefined;

  return (
    <>
      <GlobalStyles />
      {state.hasTag("open") && (
        <WidgetBackDrop
          onClick={() => send("HIDE")}
          data-testid="widget-lbw-backdrop"
        ></WidgetBackDrop>
      )}

      <WidgetTriggerContainer position="bottom">
        {state.matches("thankYou") && (
          <ThankYouContainer data-testid="lbw-thank-you">
            <Trans
              i18nKey={
                !isSubscription && isPaymentDone
                  ? "thankYouMessagePayment" // payment done
                  : isPaymentDone && isSubscription
                  ? "subscriptions.thankYouMessage" // subscription done
                  : "thankYouMessage" // Generic done
              }
              values={{
                timeDelta,
                merchant: state.context.merchant,
              }}
            />
          </ThankYouContainer>
        )}
        <WidgetTrigger
          onClick={() => send("TOGGLE")}
          validTo={state.context.userAccessValidTo}
          accessTime={accessTotalTime}
          isOpen={state.hasTag("open")}
          isThankyouOpen={state.matches("thankYou")}
        />
      </WidgetTriggerContainer>
      {state.hasTag("open") && (
        <WidgetContainer data-testid="lbw-container">
          <WidgetTriggerContainer position="top">
            {isMobile && (
              <WidgetTrigger
                onClick={() => send("TOGGLE")}
                validTo={state.context.userAccessValidTo}
                accessTime={accessTotalTime}
                isOpen={true}
                isThankyouOpen={state.matches("thankYou")}
              />
            )}
          </WidgetTriggerContainer>
          {onLogin ? (
            <LoggedOut onLogIn={onLogin} />
          ) : (
            <>
              {state.hasTag("loading") ? (
                <LoadingContainer>
                  <p>
                    <Trans i18nKey="loading" />
                  </p>
                </LoadingContainer>
              ) : (
                <Overview
                  merchant={state.context.merchant}
                  userAccessValidTo={state.context.userAccessValidTo}
                  selectedOffering={state.context.selectedOffering}
                  tab={state.context.tab}
                  defaultTabLimit={state.context.defaultTabLimit}
                  currency={state.context.currency}
                  accessDetails={state.context.userAccessDetails}
                  subscription={state.context.subscription ?? undefined}
                  state={
                    state.matches("active.showingTabDetails")
                      ? OverviewState.TabDetails
                      : state.matches("active.error")
                      ? OverviewState.Error
                      : OverviewState.Initial
                  }
                  onToggleTabDetails={() => send("TOGGLE_TAB_DETAILS")}
                  onGetMoreAccessClick={() => send("GET_MORE_ACCESS")}
                  onErrorTryAgainClick={() => send("TRY_AGAIN")}
                  onPayNowClick={() => send("INIT_PAYMENT")}
                />
              )}
            </>
          )}
          <FooterWrapper>
            <DefaultFooter
              color="offprimary"
              logo
              currency={state.context.currency.isoCode}
            />
          </FooterWrapper>
        </WidgetContainer>
      )}
    </>
  );
};

export default LeaveBehindWidget;
