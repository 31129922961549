import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Colors,
  TextMedium,
  TextSmall,
  TextTiny,
} from "../../../styles/GlobalStyles";

const footerVariant = (variant: string) => {
  switch (variant) {
    case "tiny":
      return TextTiny;
    case "small":
      return TextSmall;
    case "medium":
      return TextMedium;
    default:
      return "8px";
  }
};

const FooterStyle = css`
  ${TextTiny}
  line-height: 20px;
`;

export const SupertabLink = styled.a`
  ${FooterStyle}
  text-decoration: underline;
`;

export const FooterStyled = styled.footer<{
  smallPadding: boolean;
  fontVariant: string;
  color: string;
}>`
  text-align: center;
  padding: ${({ smallPadding }) => (smallPadding ? "14px 48px" : "18px 48px")};
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  color: ${({ color }) => Colors[color] ?? Colors.offprimary};

  gap: 4px;
  > p {
    margin: 0;
    ${FooterStyle}
    font-size: ${({ fontVariant }) => footerVariant(fontVariant)};
    color: ${({ color }) => Colors[color] ?? Colors.offprimary};
  }
  > a {
    font-size: ${({ fontVariant }) => footerVariant(fontVariant)};
    color: ${({ color }) => Colors[color] ?? Colors.offprimary};
  }
`;
