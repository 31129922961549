import styled from "@emotion/styled";
import { Colors } from "../../../styles/GlobalStyles";
import { EmotionTheme } from "../../../styles/types";

export const ToggleButton = styled.button<{
  checked: boolean;
  theme: EmotionTheme;
}>`
  height: 15px;
  min-width: 30px;
  width: 30px;
  border-radius: 25px;
  border: none;
  background-color: ${({ checked, theme }) =>
    checked
      ? theme.colors?.background ?? Colors.lightblack
      : Colors.disabledGray};
  position: relative;
  padding: 0 3px;
  margin: 0;
  cursor: pointer;
  transition: transform 0.1s linear, background-color 0.1s linear;
  div {
    transform: ${({ checked }) => (checked ? "translateX(170%)" : "none")};
  }
`;

export const Thumb = styled.div`
  background: white;
  border-radius: 50%;
  height: 9px;
  width: 9px;
  left: 0;
  transition: transform 0.1s linear;
  will-change: transform;
`;
