import React, { useEffect, useState, isValidElement, FC } from "react";
import AnimateHeight from "../AnimateHeight";
import {
  BodyChildren,
  BodyContainer,
  BottomSheetStyled,
  TitleStyledHeading,
  Header,
  TestModeBadge,
  Wrapper,
  InfoIconSVG,
  SubTitleStyledHeading,
} from "./styled";
import BackButton from "../../atoms/BackButton";
import DefaultFooter from "../../atoms/DefaultFooter";

const useMaxHeight = () => {
  // Limit the max height to viewport height minus 20px
  // Need to do some CSS in JS here because relative viewport height (vh)
  // doesn't work reliably on mobile.
  // See https://chanind.github.io/javascript/2019/09/28/avoid-100vh-on-mobile-web.html
  const getMaxHeight = () => window.innerHeight - 20;
  const [maxHeight, setMaxHeight] = useState(getMaxHeight);
  useEffect(() => {
    const handleResize = () => setMaxHeight(getMaxHeight());
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  });

  return maxHeight;
};

const CloseIcon = () => (
  <svg viewBox="0 0 13 12" xmlns="http://www.w3.org/2000/svg">
    <path
      fill="currentColor"
      d="M12.4893 1.20857L11.2807 0L6.48926 4.79143L1.69783 0L0.489258 1.20857L5.28069 6L0.489258 10.7914L1.69783 12L6.48926 7.20857L11.2807 12L12.4893 10.7914L7.69783 6L12.4893 1.20857Z"
    />
  </svg>
);

const InfoIcon = () => (
  <InfoIconSVG viewBox="0 0 18 18">
    <path d="M8.1 4.5H9.9V6.3H8.1V4.5ZM8.1 8.1H9.9V13.5H8.1V8.1ZM9 0C4.032 0 0 4.032 0 9C0 13.968 4.032 18 9 18C13.968 18 18 13.968 18 9C18 4.032 13.968 0 9 0ZM9 16.2C5.031 16.2 1.8 12.969 1.8 9C1.8 5.031 5.031 1.8 9 1.8C12.969 1.8 16.2 5.031 16.2 9C16.2 12.969 12.969 16.2 9 16.2Z" />
  </InfoIconSVG>
);

const BottomSheet: React.FC<
  React.PropsWithChildren<{
    className?: string;
    testId?: string;
    title?: string | React.ReactNode;
    subTitle?: string | React.ReactNode;
    onBack?: () => void;
    testMode?: boolean;
    includeFooter?: boolean;
    currency: string;
  }>
> = ({
  className,
  children,
  testId,
  title,
  subTitle,
  onBack,
  testMode = false,
  includeFooter = true,
  currency,
}) => {
  const maxHeight = useMaxHeight();
  const renderedTitle = isValidElement(title) ? (
    title
  ) : typeof title === "string" ? (
    <Title>
      <strong>{title}</strong>
    </Title>
  ) : null;
  const renderedSubTitle = isValidElement(subTitle) ? (
    subTitle
  ) : typeof subTitle === "string" ? (
    <SubTitle>{subTitle}</SubTitle>
  ) : null;

  return (
    <BottomSheetStyled
      style={{ maxHeight, transition: "max-height .3s ease-in-out" }}
      className={className}
      data-testid={testId}
    >
      <Wrapper>
        {testMode && (
          <TestModeBadge>
            <InfoIcon />
            You are currently in test mode.
          </TestModeBadge>
        )}
        <Header>
          {onBack && <BackButton onBack={onBack} />}
          {renderedTitle}
          {renderedSubTitle}
        </Header>
        {children}
        {includeFooter && <DefaultFooter logo currency={currency} />}
      </Wrapper>
    </BottomSheetStyled>
  );
};

export default BottomSheet;

export const Title: React.FC<React.PropsWithChildren<{}>> = ({ children }) => (
  <TitleStyledHeading>{children}</TitleStyledHeading>
);

export const SubTitle: React.FC<
  React.PropsWithChildren<{ textAlign?: "center" | "right" | "left" }>
> = ({ textAlign, children }) => (
  <SubTitleStyledHeading textAlign={textAlign}>
    {children}
  </SubTitleStyledHeading>
);

type BodyProps = {
  className?: string;
  variant?: "default" | "compact";
  extraMarginTop?: boolean;
  gap?: string;
};

export const Body: FC<BodyProps> = ({
  className,
  variant,
  children,
  extraMarginTop = false,
  gap,
}) => (
  <BodyContainer
    className={className}
    data-variant={variant}
    data-extraMarginTop={extraMarginTop}
  >
    <AnimateHeight justifyEnd>
      <BodyChildren gap={gap}>{children}</BodyChildren>
    </AnimateHeight>
  </BodyContainer>
);
