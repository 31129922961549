import { sendParent, sendTo } from "xstate/lib/actions";
import { LeaveBehindMachineContext, LeaveBehindMachineEvents } from "./types";
import { actions, assign } from "xstate";

export const checkAuth = actions.pure<
  LeaveBehindMachineContext,
  LeaveBehindMachineEvents
>((_) => sendParent({ type: "CHECK_AUTH" }));

export const sendGetMoreAccess = actions.pure<
  LeaveBehindMachineContext,
  LeaveBehindMachineEvents
>((_) => sendParent({ type: "CONTRIBUTE" }));

export const assignUserAuthorizedStatus = assign<
  LeaveBehindMachineContext,
  LeaveBehindMachineEvents,
  LeaveBehindMachineEvents
>((_, ev) =>
  ev.type === "LOGGED_IN" ||
  ev.type === "LOGGED_OUT" ||
  ev.type === "AUTH_FAILED"
    ? { userAuthorized: ev.type === "LOGGED_IN" }
    : {}
);

export const fetchTab = actions.pure<
  LeaveBehindMachineContext,
  LeaveBehindMachineEvents
>((ctx, ev) =>
  ctx.apiMachineRef
    ? sendTo(ctx.apiMachineRef, { type: "TAB_FETCH" })
    : undefined
);

export const assignTab = assign<
  LeaveBehindMachineContext,
  LeaveBehindMachineEvents,
  LeaveBehindMachineEvents
>((_, ev) =>
  ev.type === "DONE_TAB_FETCH" || ev.type === "DONE_TAB_ADD"
    ? { tab: ev.data.tab }
    : ev.type === "DONE_PAYMENT"
    ? { tab: undefined }
    : {}
);

export const fetchSubscription = actions.pure<
  LeaveBehindMachineContext,
  LeaveBehindMachineEvents
>((ctx) =>
  ctx.apiMachineRef
    ? sendTo(ctx.apiMachineRef, {
        type: "SUBSCRIPTION_FETCH",
        data: {
          subscriptionId: ctx.userAccessDetails[0].subscriptionId ?? undefined,
        },
      })
    : undefined
);

export const assignSubscription = assign<
  LeaveBehindMachineContext,
  LeaveBehindMachineEvents,
  LeaveBehindMachineEvents
>((ctx, ev) =>
  ev.type === "DONE_SUBSCRIPTION_FETCH"
    ? { subscription: ev.data.subscription ?? undefined }
    : {}
);

export const assignCurrency = assign<
  LeaveBehindMachineContext,
  LeaveBehindMachineEvents,
  LeaveBehindMachineEvents
>((_, ev) =>
  ev.type === "DONE_CLIENT_CONFIG"
    ? {
        currency: ev.data.currency,
      }
    : {}
);

export const assignCheckAccessResult = assign<
  LeaveBehindMachineContext,
  LeaveBehindMachineEvents,
  LeaveBehindMachineEvents
>((_, ev) =>
  ev.type === "DONE_ACCESS_CHECK"
    ? {
        userAccessStatus: ev.data.userAccessStatus,
        userAccessDetails: ev.data.userAccessDetails,
        userAccessValidTo: ev.data.userAccessValidTo,
      }
    : {}
);

export const assignApiError = assign<
  LeaveBehindMachineContext,
  LeaveBehindMachineEvents,
  LeaveBehindMachineEvents
>((_, ev) =>
  ev.type === "API_ERROR"
    ? {
        apiError: ev.data,
      }
    : {}
);

export const clearApiError = assign<
  LeaveBehindMachineContext,
  LeaveBehindMachineEvents,
  LeaveBehindMachineEvents
>((_, ev) => ({
  apiError: undefined,
}));

export const sendInitPayment = actions.pure<
  LeaveBehindMachineContext,
  LeaveBehindMachineEvents
>((ctx, ev) =>
  ctx.apiMachineRef
    ? sendTo(ctx.apiMachineRef, {
        type: "INIT_PAYMENT",
        data: { tab: ctx.tab },
      })
    : undefined
);

export const sendCheckAccess = sendParent<
  LeaveBehindMachineContext,
  LeaveBehindMachineEvents,
  LeaveBehindMachineEvents
>("CHECK_ACCESS");
