import styled from "@emotion/styled";
import { FlexCenter } from "../../../styles/GlobalStyles";

export const Back = styled.button`
  ${FlexCenter}
  justify-content: center;
  background: transparent;
  border-radius: 50%;
  padding: 16px 8px;
  cursor: pointer;
  position: absolute;
  top: max(29px);
  left: 26px;
  transform: translate(-50%, -50%);
`;
