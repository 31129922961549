import React, { FC, useEffect, useState } from "react";
import {
  ItemOffering,
  Merchant,
  User,
} from "../../../../tapper-browser-client";
import { BackawayProps, CowCustomizations, TabSummary } from "../../../types";
import Button from "../../atoms/Button";
import TabSheet, { Body, Title } from "../../organisms/TabSheet";
import {
  AccessGainedContainer,
  AlreadyHaveATab,
  InfoParagraph,
  SectionsWrapper,
  SubmitButton,
  TabIndicatorWrapper,
  TabInfoLimit,
  OfferingsGroup,
  OfferingsWrapper,
  TabInfoLimitPlaceholder,
  InfoButton,
  InfoIcon,
} from "./styled";
import Offerings from "../../../components/Offerings/Offerings";
import { Trans, useTranslation } from "../../../util/i18n";
import DefaultTabCopyChildren from "../../molecules/DefaultTabCopyChildren";
import { useTheme } from "@emotion/react";

export enum PickerState {
  Loading = "loading",
  Pick = "pick",
  Processing = "processing",
  Completed = "completed",
  CompletedAfterLogin = "CompletedAfterLogin",
}

type TSOfferingPickerProps = {
  testMode?: boolean;
  className?: string;
  merchant: Pick<Merchant, "name">;
  offerings: ItemOffering[];
  isSubscriptions: boolean;
  selectedOffering?: ItemOffering;
  onPickOffering?: (offering: ItemOffering) => void;
  onCancel?: () => void;
  onShowInfo?: () => void;
  onShowTabDetails?: () => void;
  onShowSubscriptionDetails?: (
    isSubscriptionEnabled: boolean,
    discount: number
  ) => void;
  onLogIn?: () => void;
  onSignUp: () => void;
  onBack: () => void;
  state: PickerState;
  userWasLoggedOut: boolean;
  user?: User;
  userAccessValidTo?: Date;
  backawayOffer?: BackawayProps;
  customization?: CowCustomizations;
} & TabSummary;

const TSOfferingPicker: FC<TSOfferingPickerProps> = ({
  testMode,
  className,
  merchant,
  amount,
  currency,
  limit,
  offerings,
  selectedOffering,
  isSubscriptions,
  onPickOffering,
  onCancel,
  onShowInfo,
  onShowTabDetails,
  onShowSubscriptionDetails,
  onLogIn,
  state = PickerState.Pick,
  pendingAmount,
  userWasLoggedOut,
  user,
  userAccessValidTo,
  backawayOffer,
  customization,
}) => {
  const { t } = useTranslation();
  const [timeDelta, setTimeDelta] = useState<string>("");
  const isLoggedIn = !onLogIn;

  const theme = useTheme();
  const showTabInfo = !backawayOffer && !isSubscriptions;
  const isLongTabLimit = t("tabInfoLimit").length >= 55;

  useEffect(() => {
    if (selectedOffering?.timePassDetails?.validTimedelta) {
      setTimeDelta(
        t("formattedTimeDelta", {
          value: selectedOffering.timePassDetails.validTimedelta,
        })
      );
    }
  }, [selectedOffering?.timePassDetails?.validTimedelta, t]);

  // TODO: Move this and its styled components somewhere else in the future
  // e.g. its own components folder
  const TabCopy = ({ isInfo = false }: { isInfo?: boolean }) => (
    <DefaultTabCopyChildren
      onShowInfo={onShowInfo}
      onShowTabDetails={onShowTabDetails}
      amount={amount}
      limit={limit}
      currency={currency}
      isLoggedIn={isLoggedIn}
      isInfo={isInfo}
    />
  );

  return (
    <>
      {state === PickerState.Completed ? (
        <TabSheet
          currency={currency.isoCode}
          testMode={testMode}
          className={className}
          testId="thank-you-sheet"
          title={
            <Title>
              <Trans i18nKey="thankYou" />
            </Title>
          }
        >
          <Body variant="compact" gap="30px">
            <SectionsWrapper>
              <TabIndicatorWrapper
                amount={amount}
                currency={currency}
                limit={limit}
                showSuccessIndicator={false}
                onClick={onShowTabDetails}
              />
              <AccessGainedContainer>
                <Trans
                  i18nKey="accessGained"
                  values={{
                    timeDelta,
                    merchant,
                  }}
                />
              </AccessGainedContainer>
              <TabCopy isInfo />
            </SectionsWrapper>
            <SubmitButton
              onClick={onCancel}
              testId="offering-close-button"
              autoFocus={true}
            >
              <Trans i18nKey="startReading" />
            </SubmitButton>
          </Body>
        </TabSheet>
      ) : state === PickerState.CompletedAfterLogin ? (
        <TabSheet
          currency={currency.isoCode}
          testMode={testMode}
          className={className}
          title={
            <Title>
              <Trans i18nKey="welcomeBack" />
              {user && (
                <>
                  <br />
                  {user.email}
                </>
              )}
            </Title>
          }
        >
          <Body variant="compact">
            <SectionsWrapper>
              {userAccessValidTo ? (
                <Trans
                  i18nKey="accessGrantedUntil"
                  values={{
                    merchant,
                    date: userAccessValidTo,
                  }}
                />
              ) : (
                <Trans
                  i18nKey="accessGranted"
                  values={{
                    merchant,
                  }}
                />
              )}
            </SectionsWrapper>
            <SubmitButton
              onClick={onCancel}
              testId="offering-close-button"
              autoFocus={true}
            >
              <Trans i18nKey="startReading" />
            </SubmitButton>
          </Body>
        </TabSheet>
      ) : (
        <TabSheet
          currency={currency.isoCode}
          testMode={testMode}
          className={className}
          onBack={onCancel}
          title={
            <Title>
              {customization?.offeringPickerTitle ?? (
                <Trans
                  i18nKey={
                    backawayOffer ? "notReadyToSubscribe" : "accessAllNow"
                  }
                  values={{ merchant }}
                />
              )}
            </Title>
          }
        >
          <Body variant="compact" extraMarginTop gap="24px">
            {(() => {
              switch (state) {
                case PickerState.Processing:
                case PickerState.Loading:
                case PickerState.Pick:
                  return (
                    <>
                      <OfferingsWrapper theme={theme}>
                        <OfferingsGroup>
                          <Offerings
                            currency={currency}
                            offerings={offerings}
                            onPickOffering={onPickOffering}
                            isSubscriptions={isSubscriptions}
                            onShowSubscriptionDetails={
                              onShowSubscriptionDetails
                            }
                            canSelectOfferings={!backawayOffer}
                            singleOfferingSubtitle={backawayOffer?.subtitle}
                            merchant={merchant}
                            isLoggedIn={isLoggedIn}
                          />
                        </OfferingsGroup>
                        {onLogIn && (
                          <>
                            {userWasLoggedOut ? (
                              <InfoParagraph
                                data-variant="warn"
                                data-testid="logged-out-warn"
                              >
                                <Trans
                                  i18nKey="userWasLoggedOut"
                                  components={[
                                    <Button
                                      key="logBackIn"
                                      onClick={onLogIn}
                                      variant="underline"
                                    />,
                                  ]}
                                />
                              </InfoParagraph>
                            ) : (
                              <>
                                {showTabInfo ? (
                                  <TabInfoLimit
                                    leftAlignment={
                                      isLongTabLimit ? "left" : "center"
                                    }
                                  >
                                    <InfoButton
                                      onClick={onShowInfo}
                                      aria-label="Show info"
                                      data-testid="show-info-button"
                                    >
                                      <InfoIcon />
                                    </InfoButton>
                                    <Trans
                                      i18nKey="tabInfoLimit"
                                      values={{ currency, limit }}
                                    />
                                  </TabInfoLimit>
                                ) : (
                                  <TabInfoLimitPlaceholder />
                                )}
                                <AlreadyHaveATab>
                                  <Trans
                                    i18nKey="alreadyHaveTab"
                                    components={[
                                      <Button
                                        key="logIn"
                                        onClick={onLogIn}
                                        variant="underline"
                                      />,
                                    ]}
                                  />
                                </AlreadyHaveATab>
                              </>
                            )}
                          </>
                        )}
                      </OfferingsWrapper>
                      {/*
                        Removed for now in CL-1250.

                        {!onLogIn && showTabInfo && (
                        <Header
                          amount={amount}
                          contentPosition={"above"}
                          currency={currency}
                          limit={limit}
                          loading={loading}
                          onIndicatorClick={onShowTabDetails}
                          pendingAmount={pendingAmount}
                          showElements={"both"}
                          TabCopyChildren={<TabCopy />}
                        />
                      )}*/}
                    </>
                  );
              }
            })()}
          </Body>
        </TabSheet>
      )}
    </>
  );
};

export default TSOfferingPicker;
