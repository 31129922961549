import React, { FC, useMemo } from "react";
import {
  CurrencyResponse,
  Purchase,
  Tab,
} from "../../../../tapper-browser-client";
import { Trans, useTranslation } from "../../../util/i18n";
import {
  PriceText,
  PurchaseItem,
  PurchasesList,
  PurchaseSubtitle,
  PurchaseText,
  TabDetails,
  ViewMore,
} from "./styled";
import AnimateHeight from "../../organisms/AnimateHeight";
import { parseTimeDelta } from "../../../util/misc";
import Price from "../../atoms/Price";

interface Props {
  tab: Tab;
  merchant: { name: string };
  isOpen?: boolean;
  currency: CurrencyResponse;
}

const TabPurchaseList: FC<Props> = ({ tab, isOpen, merchant, currency }) => {
  const { purchases } = tab;
  const { t, i18n } = useTranslation();
  const otherMerchantsPurchases = purchases.filter(
    (purchase: Purchase) => purchase.merchantName !== purchases[0].merchantName
  );

  const getDate = (date: string) =>
    new Date(date)
      .toLocaleDateString(i18n.language, {
        month: "numeric",
        day: "numeric",
      })
      .split(" ")
      .reverse()
      .join(" ");

  const remainingItemsPrice = useMemo(() => {
    let itemsPrice = 0;
    if (otherMerchantsPurchases.length) {
      otherMerchantsPurchases.forEach((purchase: Purchase) => {
        itemsPrice = purchase.price.amount + itemsPrice;
      });
    }
    return itemsPrice;
  }, [otherMerchantsPurchases]);

  const getFormattedPurchaseText = (timeDelta: string) => {
    return `${merchant.name} ${t("formattedTimeDelta", {
      value: timeDelta,
    })} ${t("salesModel.time_pass")}`;
  };

  // removes scroll bar when there are no items to scroll
  const isScrollable = purchases.length > 2;

  return (
    <AnimateHeight>
      {isOpen && (
        <TabDetails data-testid="lbw-purchase-list">
          <PurchaseSubtitle data-testid="purchases-subtitle">
            <Trans i18nKey="purchases" />
          </PurchaseSubtitle>
          <PurchasesList scroll={isScrollable} data-testid="purchases-wrapper">
            {purchases.map((purchase: Purchase) => (
              <PurchaseItem key={purchase.id}>
                <PurchaseText summary data-testid="purchase-text">
                  {getFormattedPurchaseText(purchase.validTimedelta)}
                </PurchaseText>
                <PurchaseText light={true} data-testid="purchase-date">
                  {getDate(purchase.purchaseDate)}
                </PurchaseText>
                <PriceText
                  data-testid="purchase-price"
                  amount={purchase.price.amount}
                  currency={currency}
                  weight="bold"
                  colorWay="darkgray"
                  showZeroCents
                />
              </PurchaseItem>
            ))}
            {!!otherMerchantsPurchases.length && (
              <ViewMore data-testid="other-publishers-link">
                <Trans
                  i18nKey={"otherPublishers"}
                  values={{
                    purchasesNumber: otherMerchantsPurchases?.length,
                  }}
                />
                <Price
                  data-testid="other-publishers-price"
                  amount={remainingItemsPrice}
                  currency={currency}
                  showZeroCents
                />
              </ViewMore>
            )}
          </PurchasesList>
        </TabDetails>
      )}
    </AnimateHeight>
  );
};

export default TabPurchaseList;
