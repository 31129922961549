import React from "react";
import { CurrencyResponse } from "../../../../tapper-browser-client/types";
import Price from "../../atoms/Price";
import Arc from "../../atoms/Arc";
import { Container, PriceContainer } from "./styled";
import Button from "../../atoms/Button";
import CurrencySymbol from "../../atoms/CurrencySymbol";

const TabIndicator: React.FC<
  React.PropsWithChildren<{
    className?: string;
    currency: CurrencyResponse;
    amount: number;
    pendingAmount?: number;
    limit: number;
    showPrice?: boolean;
    loading?: boolean;
    onClick?: () => void;
    showSuccessIndicator?: boolean;
  }>
> = ({
  className,
  currency,
  amount,
  pendingAmount,
  limit,
  showPrice = true,
  loading,
  onClick,
  showSuccessIndicator = false,
}) => {
  const bottomGapAngle = 30;
  const arcStartAngle = -180 + bottomGapAngle / 2;
  const arcEndAngle = 180 - bottomGapAngle / 2;
  const foregroundFillRatio = Math.min(amount / limit, 1);
  const pendingArcFillRatio = pendingAmount
    ? Math.min(pendingAmount / limit, 1)
    : 0;

  return (
    <Container
      onClick={onClick}
      data-actionable={!!onClick}
      data-loading={loading}
      className={className}
      data-testid="tab-indicator"
    >
      <Arc
        strokeWidth={8}
        startAngle={arcStartAngle}
        endAngle={arcEndAngle}
        colorWay="lightgray"
      />
      {!loading && (
        <>
          <Arc
            strokeWidth={8}
            startAngle={arcStartAngle}
            endAngle={arcEndAngle}
            fillRatio={pendingArcFillRatio}
            colorWay="darkgray"
          />
          <Arc
            strokeWidth={8}
            startAngle={arcStartAngle}
            endAngle={arcEndAngle}
            fillRatio={foregroundFillRatio}
          />
        </>
      )}
      {showPrice &&
        (loading ? (
          <>&#183;&#183;&#183;</>
        ) : (
          <PriceContainer>
            <Price
              amount={amount}
              currency={currency}
              weight="medium"
              colorWay="darkgray"
              showZeroCents
            />
            <CurrencySymbol isoCode={currency.isoCode} onlyWhenSameAsIsoCode />
          </PriceContainer>
        ))}
      {showSuccessIndicator && (
        <Button variant="icon">
          <svg
            width="10"
            height="9"
            viewBox="0 0 10 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.66485 0.87077L7.66467 0.871071L4.63029 5.8985L2.58187 3.80662L2.58104 3.80577C2.42549 3.64768 2.20584 3.55958 1.97278 3.58851C1.74273 3.61707 1.55661 3.75201 1.44098 3.92758L1.44097 3.92758L1.43942 3.92996C1.23019 4.25162 1.25184 4.71727 1.53806 5.00984L1.53824 5.01003L4.2474 7.77639L4.24865 7.77766C4.37776 7.90856 4.56067 8 4.76947 8C4.80078 8 4.83189 7.99804 4.86268 7.99419L4.87057 7.9932L4.87842 7.99197C5.11535 7.95467 5.2978 7.80705 5.40481 7.63088L5.40554 7.62967L8.93629 1.77975L8.93679 1.77892C9.13257 1.45312 9.10132 0.987571 8.80087 0.703657C8.63631 0.546952 8.40994 0.470339 8.17898 0.510484C7.9521 0.549919 7.7729 0.692045 7.66485 0.87077Z"
              fill="white"
              stroke="white"
            />
          </svg>
        </Button>
      )}
    </Container>
  );
};

export default TabIndicator;
