import { FC, PropsWithChildren } from "react";
import Measure from "react-measure";
import { Container } from "./styled";

const AnimateHeight: FC<PropsWithChildren<{ justifyEnd?: boolean }>> = ({
  justifyEnd = false,
  children,
}) => (
  <Measure bounds>
    {({ measureRef, contentRect }) => (
      <Container
        style={{
          height: contentRect.bounds?.height,
          transition: "height .3s ease-in-out",
        }}
        data-justify-end={justifyEnd}
      >
        <div ref={measureRef}>
          <Container>{children}</Container>
        </div>
      </Container>
    )}
  </Measure>
);

export default AnimateHeight;
