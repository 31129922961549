import React from "react";
import { TabSummary } from "../../../types";
import Button from "../../atoms/Button";
import AnimateHeight from "../AnimateHeight";
import BottomSheet, { Body, Title } from "../BottomSheet";
import TabIndicator from "../TabIndicator";
import {
  Content,
  HeaderStyled,
  TabActions,
  TabContainer,
  TabCopy,
} from "./styled";

export default BottomSheet;
export { Title, Body };

// TODO: Rename this component something else since it's being used in a non-header manner
// or keep it as a Header but probbaly extract the Tab into its own thing
export const Header: React.FC<
  React.PropsWithChildren<
    {
      className?: string;
      contentPosition?: "above" | "below";
      loading?: boolean;
      onIndicatorClick?: () => void;
      showElements?: "tab" | "content" | "both";
      showSuccessIndicator?: boolean;
      TabCopyChildren?: React.ReactNode;
      TabActionsChildren?: React.ReactNode;
    } & TabSummary
  >
> = ({
  amount,
  children,
  className,
  contentPosition = "below",
  currency,
  limit,
  loading,
  onIndicatorClick,
  pendingAmount,
  showElements = "both",
  showSuccessIndicator,
  TabCopyChildren,
  TabActionsChildren,
}) => {
  const showContent = showElements === "both" || showElements === "content";
  return (
    <AnimateHeight>
      <HeaderStyled className={className}>
        {showContent && contentPosition === "above" && children && (
          <Content>{children}</Content>
        )}
        {(showElements === "both" || showElements === "tab") && (
          <TabContainer>
            <TabIndicator
              amount={amount}
              currency={currency}
              limit={limit}
              onClick={onIndicatorClick}
              loading={loading}
              pendingAmount={pendingAmount}
              showSuccessIndicator={showSuccessIndicator}
            />
            {TabCopyChildren && <TabCopy>{TabCopyChildren}</TabCopy>}
            {TabActionsChildren && (
              <TabActions>{TabActionsChildren}</TabActions>
            )}
          </TabContainer>
        )}
        {showContent && contentPosition === "below" && children && (
          <Content>{children}</Content>
        )}
      </HeaderStyled>
    </AnimateHeight>
  );
};
