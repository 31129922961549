import styled from "@emotion/styled";
import { Body as SheetBody } from "../../organisms/BottomSheet";
import {
  Colors,
  FlexCol,
  TextMedium,
  TextSmall,
} from "../../../styles/GlobalStyles";
import Button from "../../atoms/Button";
import TabIndicator from "../../molecules/TabIndicator/index";

export const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
`;

export const TabIndicatorStyled = styled(TabIndicator)`
  width: 40px;
  height: 40px;
  svg {
    width: 30px;
    height: 30px;
    stroke-width: 10px;
  }
`;

export const Body = styled(SheetBody)`
  ${FlexCol}
  max-width: 270px;
  min-height: 33vh;
  justify-content: center;
  gap: 14px;
  margin: 16px auto;
  text-align: center;

  h1 {
    font-weight: 700;
    ${TextMedium}
  }

  p {
    ${TextSmall}
    line-height: 20px;
  }

  button {
    margin: 16px 0 0;
  }
`;

export const TheButton = styled(Button)`
  &[data-variant="quiet"] {
    text-decoration: none;
    color: ${Colors.purple};
  }
`;
