import styled from "@emotion/styled";
import {
  Colors,
  FlexGrow,
  TextMedium,
  TextSmall,
} from "../../../styles/GlobalStyles";
import { Body } from "../../organisms/BottomSheet";

export const ErrorTitle = styled.h2`
  ${TextMedium}
  font-weight: 700;
  text-align: center;
  letter-spacing: -0.01em;
  margin-top: 46px;
  color: ${Colors.black};
`;

export const ErrorParagraph = styled.span`
  ${TextSmall}
  line-height: 24px;
  text-align: center;
  margin-bottom: 14px;
  color: ${Colors.black};
`;

export const ErrorBody = styled(Body)`
  margin-bottom: -20px;
`;

export const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 10px;

  button {
    ${FlexGrow}
  }
`;

export const ContactMessage = styled.div`
  ${TextSmall}
  color: ${Colors.black};
  font-weight: 300;
  text-align: center;
  a {
    text-decoration: underline;
  }
`;
