import React, { FC, ReactNode } from "react";
import { CurrencyResponse, Tab } from "../../../../tapper-browser-client";
import Price from "../../atoms/Price";
import { Body } from "../../organisms/BottomSheet";
import Button from "../../atoms/Button";
/* @ts-ignore-line */
import { ReactComponent as ExternalLinkIcon } from "../../../../svg/external-link-12.svg";
import { Trans, useTranslation } from "../../../util/i18n";

/* @ts-ignore-line */
import {
  Item,
  ItemBottom,
  ItemDate,
  ItemInner,
  ItemTitle,
  ListItemContainer,
  StyledBottomSheet,
  DividedList,
  ViewAllLink,
  NoPurchases,
  MainTabindicator,
  Headline,
} from "./styled";
import { Paragraph } from "../../../styles/GlobalStyles";

const ListItem: FC<{
  title: ReactNode | string;
  date?: Date;
  currency: CurrencyResponse;
  amount: number;
  description: ReactNode | string;
}> = ({ title, date, currency, amount, description }) => (
  <ListItemContainer>
    <Item>
      <ItemInner>
        <ItemTitle>{title}</ItemTitle>
        <Price
          weight="bold"
          amount={amount}
          currency={currency}
          showZeroCents
        />
      </ItemInner>
      <ItemBottom>
        {date && (
          <ItemDate>
            <Trans i18nKey="formattedDate" values={{ date }} />
          </ItemDate>
        )}
        <div>{description}</div>
      </ItemBottom>
    </Item>
  </ListItemContainer>
);

const TSTabDetails: FC<{
  onBack: () => void;
  tab?: Tab;
  currency: CurrencyResponse;
  testMode?: boolean;
  userPortalBaseUrl: URL;
}> = ({ onBack, tab, currency, testMode, userPortalBaseUrl }) => {
  const { t } = useTranslation();
  const viewTransactionsUrl = new URL(
    `/?testmode=${tab?.testMode ? "true" : "false"}`,
    userPortalBaseUrl
  ).toString();

  return (
    <StyledBottomSheet
      title={t("myTab")}
      testMode={testMode}
      currency={currency.isoCode}
    >
      {tab && (
        <>
          <MainTabindicator
            currency={currency}
            amount={tab.total}
            limit={tab.limit}
            showPrice
          />
          <Headline>
            <Trans
              i18nKey="youUsedAmountOfLimit"
              components={[<span key="price" />]}
              values={{
                amount: tab.total,
                currency: currency,
                limit: tab.limit,
              }}
            />
          </Headline>
        </>
      )}
      <Body variant="compact">
        {tab ? (
          <DividedList>
            <ListItemContainer>
              <ItemTitle>
                <Trans i18nKey="purchases" />
              </ItemTitle>
            </ListItemContainer>
            {tab.purchases
              .sort(
                (a, b) =>
                  new Date(b.purchaseDate).getTime() -
                  new Date(a.purchaseDate).getTime()
              )
              .map((purchase) => (
                <ListItem
                  key={purchase.id}
                  title={t(`salesModel.${purchase.salesModel}`)}
                  date={new Date(purchase.purchaseDate)}
                  currency={currency}
                  amount={purchase.price.amount}
                  description={purchase.summary}
                />
              ))}
            {tab.tabStatistics.obfuscatedPurchasesCount > 0 && (
              <ListItem
                title={<Trans i18nKey="otherPurchases" />}
                currency={currency}
                amount={tab.tabStatistics.obfuscatedPurchasesTotal}
                description={
                  <Trans
                    i18nKey="itemsFromAnotherSite"
                    values={{
                      count: tab.tabStatistics.obfuscatedPurchasesCount,
                    }}
                  />
                }
              />
            )}
            <li>
              <ViewAllLink href={viewTransactionsUrl} target="cto-user-portal">
                <Trans i18nKey="viewAllTransactions" />
                <ExternalLinkIcon />
              </ViewAllLink>
            </li>
          </DividedList>
        ) : (
          <NoPurchases>
            <Paragraph>
              <Trans i18nKey="noPurchasesYet" />
            </Paragraph>
          </NoPurchases>
        )}
        <Button onClick={() => onBack?.()}>
          <Trans i18nKey="goBack" />
        </Button>
      </Body>
    </StyledBottomSheet>
  );
};

export default TSTabDetails;
