import { createMachine } from "xstate";
import { LeaveBehindMachineContext, LeaveBehindMachineEvents } from "./types";
import * as actions from "./actions";
import * as guards from "./guards";
import * as delays from "./delays";

const LeaveBehindWidgetMachine = createMachine<
  LeaveBehindMachineContext,
  LeaveBehindMachineEvents
>(
  {
    id: "leavebehindWidget",
    strict: true,
    preserveActionOrder: true,
    predictableActionArguments: true,
    context: {} as LeaveBehindMachineContext,
    initial: "inactive",

    on: {
      DONE_ACCESS_CHECK: [
        {
          actions: "assignCheckAccessResult",
          target: "processAccessResult",
        },
      ],
      DONE_TAB_FETCH: {
        actions: "assignTab",
      },
      DONE_CLIENT_CONFIG: {
        actions: "assignCurrency",
      },
      LOGGED_OUT: {
        actions: "assignUserAuthorizedStatus",
      },
    },

    states: {
      inactive: {
        entry: "checkAuth",
        on: {
          TOGGLE: "active",
          AUTH_FAILED: {
            actions: ["assignUserAuthorizedStatus", "assignAuthFailed"],
          },
          LOGGED_IN: {
            actions: "assignUserAuthorizedStatus",
          },
          DONE_TAB_ADD: {
            actions: "assignTab",
          },
          DONE_ACCESS_CHECK: [
            {
              cond: "shouldShowThankYou",
              actions: "assignCheckAccessResult",
              target: "thankYou",
            },
            {
              actions: "assignCheckAccessResult",
              target: "processAccessResult",
            },
          ],
          DONE_PAYMENT: {
            actions: "assignTab",
          },
        },
      },

      processAccessResult: {
        always: [
          {
            cond: "hasSubscription",
            target: "fetchingSubscription",
          },
          {
            target: "inactive",
          },
        ],
      },

      fetchingSubscription: {
        tags: ["loading"],
        entry: "fetchSubscription",
        on: {
          DONE_SUBSCRIPTION_FETCH: {
            actions: "assignSubscription",
            target: "inactive",
          },
          TOGGLE: "active",
          API_ERROR: {
            actions: "assignApiError",
            target: "inactive",
          },
        },
      },

      thankYou: {
        on: {
          TOGGLE: "active",
        },
        after: {
          THANK_YOU_MESSAGE_DURATION: { target: "processAccessResult" },
        },
      },

      fetchingTab: {
        tags: ["loading"],
        entry: "fetchTab",
        on: {
          DONE_TAB_FETCH: {
            actions: "assignTab",
            target: "inactive",
          },
          DONE_ACCESS_CHECK: {
            actions: "assignCheckAccessResult",
            target: "processAccessResult",
          },
          API_ERROR: {
            actions: "assignApiError",
            target: "inactive",
          },
        },
      },

      active: {
        tags: ["open"],
        initial: "idle",
        on: {
          TOGGLE: [
            {
              cond: "isAccessFinished",
              actions: "sendCheckAccess",
              target: "inactive",
            },

            {
              target: "inactive",
            },
          ],
          HIDE: "inactive",
          LOGGED_IN: {
            actions: "assignUserAuthorizedStatus",
          },
          AUTH_FAILED: {
            actions: "assignUserAuthorizedStatus",
          },
          GET_MORE_ACCESS: {
            actions: "sendGetMoreAccess",
          },
          INIT_PAYMENT: {
            target: ".payment",
          },
          DONE_TAB_ADD: {
            actions: "assignTab",
          },
          DONE_SUBSCRIPTION_FETCH: {
            actions: "assignSubscription",
          },
        },
        states: {
          idle: {
            always: [
              {
                cond: "hasApiError",
                target: "error",
              },
            ],
            on: {
              TOGGLE_TAB_DETAILS: {
                target: "showingTabDetails",
              },
            },
          },
          showingTabDetails: {
            on: {
              TOGGLE_TAB_DETAILS: "idle",
            },
          },
          payment: {
            entry: "sendInitPayment",
            on: {
              INIT_PAYMENT: {
                actions: "sendInitPayment",
              },
              DONE_PAYMENT: {
                actions: "assignTab",
                target: "idle",
              },
            },
          },
          error: {
            on: {
              TRY_AGAIN: {
                actions: "clearApiError",
                target: "idle",
              },
            },
          },
        },
      },
    },
  },
  {
    actions,
    guards,
    delays,
  }
);

export default LeaveBehindWidgetMachine;
