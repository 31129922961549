/** String transform function */
export const snakeToCamel = (s: string) =>
  s.replace(/_[a-z]/g, (word) => word.charAt(1).toUpperCase());

/** String transform function */
export const camelToSnake = (s: string) =>
  s.replace(
    /[a-z][A-Z]/g,
    (word) => word.charAt(0) + "_" + word.charAt(1).toLowerCase()
  );

/**
 * Strict object test
 *
 * Returns true if `item` is defined and is an object but not an array, else false.
 */
export const isObject = (item: any) =>
  !!item && typeof item === "object" && !Array.isArray(item);

/** Simplified type checking */
export const typeOf = (value: any) => {
  if (isObject(value)) return "object";
  if (Array.isArray(value)) return "array";
  return "other";
};

/**
 * Deeply transform object keys using `transformFunction`
 *
 * Iterates over object keys and array items, preserves all primitive value types
 */
export const deeplyTransformObjectKeys =
  (transformFunction: (s: string) => string) => (item: any) => {
    switch (typeOf(item)) {
      case "object":
        return Object.keys(item).reduce((_obj, key) => {
          const value = item[key];
          (_obj as any)[transformFunction(key)] =
            "object" === typeof value && null !== value
              ? deeplyTransformObjectKeys(transformFunction)(value)
              : value;
          return _obj;
        }, {});
      case "array":
        return item.map(deeplyTransformObjectKeys(transformFunction));
      default:
        return item;
    }
  };

/** Object transform function */
export const transformSnakeObjToCamel = deeplyTransformObjectKeys(snakeToCamel);

/** Object transform function */
export const transformCamelObjToSnake = deeplyTransformObjectKeys(camelToSnake);

// OAuth2 PKCE utils heavily inspired by
// https://github.com/curityio/pkce-javascript-example/blob/master/index.html

export async function generateCodeChallenge(
  codeVerifier: string
): Promise<string> {
  const digest = await crypto.subtle.digest(
    "SHA-256",
    new TextEncoder().encode(codeVerifier)
  );

  return btoa(String.fromCharCode(...Array.from(new Uint8Array(digest))))
    .replace(/=/g, "")
    .replace(/\+/g, "-")
    .replace(/\//g, "_");
}

export function generateRandomString(length: number): string {
  let text = "";
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
}
