import React, { FC } from "react";
import { environment } from "../../../../environment";
import { Trans } from "../../../util/i18n";
import SupertabLogo from "../SupertabLogo";
import { FooterStyled, SupertabLink } from "./styled";
import Flag from "../Flag";

interface Props {
  smallPadding?: boolean;
  fontVariant?: string;
  color?: string;
  logo?: boolean;
  currency?: string;
}

const DefaultFooter: FC<Props> = ({
  smallPadding = false,
  fontVariant = "",
  color = "offprimary",
  logo = false,
  currency,
}) => {
  return (
    <FooterStyled
      smallPadding={smallPadding}
      fontVariant={fontVariant}
      color={color}
    >
      {logo ? (
        <SupertabLink
          href={environment.personalPageUrl}
          target="_blank"
          data-testid="lbw-footer"
        >
          <SupertabLogo long />
        </SupertabLink>
      ) : (
        <>
          <Trans i18nKey="poweredBy" parent="p" />
          <SupertabLink href={environment.personalPageUrl} target="_blank">
            Supertab
          </SupertabLink>
        </>
      )}
      {currency && (
        <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
          <span> | </span>
          <Flag id={currency} size="10px" />
          <span style={{ fontSize: 12 }}>{currency}</span>
        </div>
      )}
    </FooterStyled>
  );
};

export default DefaultFooter;
