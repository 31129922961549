import styled from "@emotion/styled";
import {
  Colors,
  ParagraphCss,
  FlexGrow,
  TextSmall,
  TextTiny,
} from "../../../styles/GlobalStyles";
import TabIndicator from "../../molecules/TabIndicator";
import BottomSheet from "../../organisms/BottomSheet";

export const StyledBottomSheet = styled(BottomSheet)``;

export const DividedList = styled.ul`
  margin-top: 0;
  > li {
    border-bottom: 1px solid ${Colors.lightgray};
    &:first-of-type {
      padding-top: 0;
    }
    &:last-of-type {
      border-bottom: 0;
    }
  }
`;

export const ListItemContainer = styled.li`
  display: flex;
  padding: 16px 0;
  gap: 12px;
  justify-content: flex-end;
`;

export const Item = styled.div`
  ${FlexGrow}
`;

export const ItemInner = styled.div`
  display: flex;
  ${TextSmall}
`;

export const ItemTitle = styled.div`
  ${FlexGrow}
  font-weight: 700;
  color: ${Colors.lightblack};
`;

export const ItemBottom = styled.div`
  color: ${Colors.lightblack};
  font-weight: 400;
  ${TextTiny}
`;

export const ItemDate = styled.div`
  ${TextTiny}
`;

export const ViewAllLink = styled.a`
  display: block;
  text-align: center;
  margin-top: 10px;
  color: ${Colors.primary};
  text-decoration: underline;

  svg {
    margin-left: 5px;
    display: inline-block;
    margin-bottom: 4px;
    color: ${Colors.lightgray};
  }
`;

export const NoPurchases = styled.div`
  padding: 16px 0;
  text-align: center;
`;

export const MainTabindicator = styled(TabIndicator)`
  align-self: center;
  margin: 12px 0;
`;

export const Headline = styled.p`
  ${ParagraphCss};
  align-self: center;
  margin: 1rem 0;
  ${TextSmall}
  color: ${Colors.lightblack};
`;
