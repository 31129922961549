import React, { FC } from "react";
import { TabSummary } from "../../../types";
import { StyledTabIndicator } from "./styled";

type TabIndicatorProps = {
  onClick?: () => void;
  loading?: boolean;
  showSuccessIndicator?: boolean;
} & TabSummary;

const TabIndicator: FC<TabIndicatorProps> = ({
  amount,
  currency,
  limit,
  loading,
  onClick,
  pendingAmount,
  showSuccessIndicator,
}) => {
  return (
    <StyledTabIndicator
      amount={amount}
      currency={currency}
      limit={limit}
      onClick={onClick}
      loading={loading}
      pendingAmount={pendingAmount}
      showSuccessIndicator={showSuccessIndicator}
    />
  );
};

export default TabIndicator;
