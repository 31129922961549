import { Resource } from "i18next";

// @ts-ignore
const files = require.context("./resources/", true, /\.json/);

// @ts-ignore
const languages = files.keys().reduce((acc: Resource, each) => {
  const match = each.match(/(\w+)\//);
  if (match) {
    acc[match[1].replace("_", "-")] = files(each);
  }
  return acc;
}, {});

export default languages;
