/**
 * Monetization Provider API v1.0.0 types ported from
 * https://developers.google.com/funding-choices/monetization-provider-docs
 */

export interface MonetizationProvider {
  constructor: Function;
  initialize: (
    initializeParams: InitializeParams
  ) => Promise<InitializeResponse>;
  getUserEntitlementState: () => Promise<UserEntitlementStateEnumDefaultType>;
  monetize: (monetizeParams: MonetizeParams) => Promise<MonetizeResponse>;
  destroy: (destroyParams: DestroyParams) => void;
}

export interface InitializeParams {
  // The loaded monetization provider API version. i.e. "1.0.0"
  currentApiVersion: string;
  // The language code suggested for the provider to use, as defined by BCP 47.
  suggestedLanguageCode?: string;
  // The styles suggested for the provider to use.
  suggestedStyles?: Styles;
  // The publisher's logo url.
  publisherLogoUrl?: string;
  // Provider-specific params.
  additionalInitializeParams?: {
    merchantName?: string;
    clientId?: string;
  };
}

export interface Styles {
  // The primary color of the Offerwall.
  primaryColor?: string;
  // The background color of the Offerwall.
  backgroundColor?: string;
}

export interface InitializeResponse {
  // Whether or not initialization was successful. If initialization is
  // unsuccessful, the Offerwall does not proceed to call other provider methods
  // except for destroy.
  initializeSuccess: boolean;
  // The monetization provider API version that the provider is using. If the
  // indicated major version is not equal to the major version of the API
  // currently on the page, provider execution is halted.
  apiVersionInUse: string;
  // Whether or not the optional sign-in monetization portal is supported. If
  // you indicate that it is supported, the Offerwall renders a sign-in link
  // that will invoke your sign-in portal upon user click.
  signInMonetizationPortalSupported: boolean;
}

export enum MonetizationPortalEnumDefaultType {
  PORTAL_UNKNOWN = "PORTAL_UNKNOWN",
  // The primary access portal represents a provider's main entry point into a
  // monetization flow, and must always be supported.
  PORTAL_PRIMARY_ACCESS = "PORTAL_PRIMARY_ACCESS",
  // The sign in portal represents a provider's monetization entry point that
  // usually begins with the user performing some sign-in or registration
  // action. Provider support for this monetization portal type is optional.
  PORTAL_SIGN_IN = "PORTAL_SIGN_IN",
}

export interface MonetizeParams {
  // The monetization portal that the Offerwall wants to invoke. You can
  // indicate whether you support any optional portals in your
  // InitializeResponse; the only portal that isn't optional is
  // MonetizationPortalEnum.PORTAL_PRIMARY_ACCESS. The Offerwall provides the
  // portal enum for the flow requested by the user.
  monetizationPortal: MonetizationPortalEnumDefaultType;
}

export interface MonetizeResponse {
  // The user's current entitlement state.
  userEntitlementState: UserEntitlementStateEnumDefaultType;
  // The user's granted entitlement type, only populated if an entitlement was
  // granted within the scope of the current MonetizationProvider.monetize
  // invocation.
  newlyGrantedUserEntitlementType?: EntitlementTypeEnumDefaultType;
  // The user's granted entitlement value, only populated if an entitlement was
  // granted within the scope of the current MonetizationProvider.monetize
  // invocation.
  newlyGrantedUserEntitlementValue?: number;
  // The amount paid by the user, only populated if a payment occurred within
  // the scope of the current MonetizationProvider.monetize invocation.
  newlyPaidAmountByUser?: Money;
  // The recurrence of the monetization event, populated only if the
  // monetization event occurred within the scope of the current
  // MonetizationProvider.monetize invocation & the monetization event is
  // expected to auto-recur without further action from the user (e.g.
  // registering for a monthly subscription)
  recurrenceOfNewMonetizationEvent?: MonetizationRecurrenceEnumDefaultType;
  // Provider-specific additional data
  additionalMonetizeResponseData?: AdditionalMonetizeResponseData;
}

export interface AdditionalMonetizeResponseData {
  // The amount added to the Tab
  newlyPledgedAmountByUser?: Money;
}

export enum UserEntitlementStateEnumDefaultType {
  ENTITLED_UNKNOWN = "ENTITLED_UNKNOWN",
  // The user is currently entitled to access page content.
  ENTITLED_YES = "ENTITLED_YES",
  // The user is not currently entitled to access page content.
  ENTITLED_NO = "ENTITLED_NO",
}

export enum EntitlementTypeEnumDefaultType {
  TYPE_UNKNOWN = "TYPE_UNKNOWN",
  // This type is used if the user is awarded a positive integer value of
  // Offerwall-free pageviews.
  TYPE_PAGEVIEW_COUNT = "TYPE_PAGEVIEW_COUNT",
  // This type is used if the user is awarded a positive integer value of
  // seconds (duration) in which they can access Offerwall-free page content any
  // number of times.
  TYPE_DURATION_SECONDS = "TYPE_DURATION_SECONDS",
}

export interface Money {
  // The three-letter currency code defined in ISO 4217.
  currencyCode: string;
  // The whole units of the amount.
  // For example if `currencyCode` is `"USD"`, then 1 unit is one US dollar.
  units?: number;
  // Number of nano (10^-9) units of the amount.
  // The value must be between -999,999,999 and +999,999,999 inclusive.
  // If `units` is positive, `nanos` must be positive or zero.
  // If `units` is zero, `nanos` can be positive, zero, or negative.
  // If `units` is negative, `nanos` must be negative or zero.
  // For example $-1.75 is represented as `units`=-1 and `nanos`=-750,000,000.
  nanos?: number;
}

export enum MonetizationRecurrenceEnumDefaultType {
  MONETIZATION_RECURRENCE_UNKNOWN = "MONETIZATION_RECURRENCE_UNKNOWN",
  MONETIZATION_RECURRENCE_WEEKLY = "MONETIZATION_RECURRENCE_WEEKLY",
  MONETIZATION_RECURRENCE_MONTHLY = "MONETIZATION_RECURRENCE_MONTHLY",
  MONETIZATION_RECURRENCE_ANNUALLY = "MONETIZATION_RECURRENCE_ANNUALLY",
}

export interface DestroyParams {
  // The reason for destroying the provider.
  destroyReason: DestroyReasonEnumDefaultType;
}

export enum DestroyReasonEnumDefaultType {
  REASON_UNKNOWN = "REASON_UNKNOWN",
  // The Offerwall no longer needs to invoke the monetization provider on the
  // pageview
  REASON_CALLER_FINISHED = "REASON_CALLER_FINISHED",
  // The Offerwall encountered an erroneous state with the monetization provider
  // in the midst of the provider's lifecycle.
  REASON_ERROR_STATE = "REASON_ERROR_STATE",
  // The API version that the monetization provider is currently using is no
  // longer supported.
  REASON_UNSUPPORTED_API_VERSION = "REASON_UNSUPPORTED_API_VERSION",
}
