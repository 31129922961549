import React, { FC } from "react";
import { TabSummary } from "../../../types";
import {
  Chevron,
  HeaderStyled,
  TabContainer,
  TabCopy,
  TabOverview,
  WidgetTabIndicator,
} from "./styled";
import { Trans, useTranslation } from "../../../util/i18n";
import TabPurchaseList from "./TabPurchaseList";
import {
  CurrencyResponse,
  ItemOffering,
  Merchant,
  Tab,
} from "../../../../tapper-browser-client";

interface WidgetTabInfoProps {
  tab?: Tab | null;
  selectedOffering?: ItemOffering;
  defaultTabLimit: number;
  currency: CurrencyResponse;
  merchant: Pick<Merchant, "name">;
  className?: string;
  showDetails: boolean;
  onTabClick: () => void;
}

const WidgetTabInfo: FC<WidgetTabInfoProps> = ({
  tab,
  defaultTabLimit,
  currency,
  className,
  showDetails,
  onTabClick,
  merchant,
}) => {
  const { t } = useTranslation();

  return (
    <HeaderStyled className={className}>
      <TabContainer>
        <TabOverview>
          <WidgetTabIndicator
            data-testid="tab-indicator"
            amount={tab?.total ?? 0}
            currency={currency}
            limit={tab?.limit ?? defaultTabLimit}
            onClick={onTabClick}
            pendingAmount={0}
          />
          <TabCopy
            onClick={onTabClick}
            hasItems={!!tab?.purchases}
            data-testid="lbw-my-tab"
          >
            <Trans i18nKey="myTab" />{" "}
            {tab?.purchases && <Chevron key="chevron" isOpen={showDetails} />}
          </TabCopy>
        </TabOverview>
        {tab?.purchases && (
          <TabPurchaseList
            tab={tab}
            isOpen={showDetails}
            merchant={merchant}
            currency={currency}
          />
        )}
      </TabContainer>
    </HeaderStyled>
  );
};

export default WidgetTabInfo;
