import {
  AuthenticationMachineContext,
  AuthenticationMachineEvents,
} from "./types";

import { ActionTypes } from "xstate";

export function hasTokens(ctx: AuthenticationMachineContext) {
  return !!ctx.accessToken && !!ctx.refreshToken;
}

export function isTokenExpired(
  _: AuthenticationMachineContext,
  evt: AuthenticationMachineEvents
) {
  if (evt.type === "error.platform.identifyUser") {
    return evt.data.message.includes("token has expired");
  } else {
    return false;
  }
}

export function isNotInitializing(
  _: AuthenticationMachineContext,
  evt: AuthenticationMachineEvents
) {
  return evt.type !== ActionTypes.Init;
}
