import BottomSheet from "../../organisms/BottomSheet";
import { Body, LogoWrapper, TabIndicatorStyled, TheButton } from "./styled";
import { Trans, useTranslation } from "../../../util/i18n";
import { CurrencyResponse } from "../../../../tapper-browser-client/types";
import { useEffect, useState } from "react";

export enum BlockerState {
  Auth = "auth",
  Payment = "payment",
}

const TSBlocker = ({
  onRetry,
  state,
  testMode,
  currency,
}: {
  onRetry: () => void;
  state: BlockerState | null;
  testMode?: boolean;
  currency: CurrencyResponse;
}) => {
  const { t } = useTranslation();
  const titlei18nKey =
    state === BlockerState.Auth ? "almostThere" : "youCompletedYourTab";
  const texti18nKey =
    state === BlockerState.Auth
      ? "youHaveBeenRedirectedToLogin"
      : "youCanPayYourTab";

  const [amount, setAmount] = useState<number>(0);

  const amountTimeout = setTimeout(() => {
    state === BlockerState.Payment && setAmount(500);
  }, 250);

  useEffect(() => {
    return () => {
      clearTimeout(amountTimeout);
    };
  });

  if (!state) {
    return null;
  }

  return (
    <BottomSheet
      testId="blocker-sheet"
      testMode={testMode}
      includeFooter={false}
      currency={currency.isoCode}
    >
      <Body data-testid={`blocker-${BlockerState}`}>
        <LogoWrapper>
          <TabIndicatorStyled
            amount={amount}
            currency={currency}
            limit={500}
            showPrice={false}
          />
        </LogoWrapper>
        <Trans i18nKey={titlei18nKey} parent="h1" />
        <Trans i18nKey={texti18nKey} parent="p" />
        <TheButton
          onClick={onRetry}
          variant="quiet"
          data-testid="blocker-sheet-button"
        >
          <Trans i18nKey="takeMeThereAgain" />
        </TheButton>
      </Body>
    </BottomSheet>
  );
};

export default TSBlocker;
