import { FC, useEffect, useMemo } from "react";
import { useTransition } from "react-spring";
/* @ts-ignore-line */
import { OnpageWidgetService } from "../../statecharts/onpageWidget/types";
import { springConfig } from "../../util/misc";
import TSOfferingPicker, { PickerState } from "../sheets/TSOfferingPicker";
import TSTabInfo from "../sheets/TSTabInfo";
import TSError from "../sheets/TSError";
import TSOffpagePayment, {
  OffpagePaymentState,
} from "../sheets/TSOffpagePayment";
import TSTabDetails from "../sheets/TSTabDetails";
import { GlobalStyles } from "../../styles/GlobalStyles";
import { BackDrop, AnimatedContainer } from "./styled";
import { cowConsole } from "../../../util/cowConsole";
import FocusTrap from "focus-trap-react";
import { initi18n } from "../../util/i18n";
import { useShortcut } from "../../hooks/useShortcut";
import LeaveBehindWidget from "../LeaveBehindWidget";
import { useActor } from "@xstate/react";
import TSBlocker, { BlockerState } from "../sheets/TSBlocker";

export enum OnpageWidgetState {
  Inactive = "inactive",
  OfferingPicker = "offering_picker",
  SimplifiedFlow = "simplified_flow",
  OffpagePayment = "offpage_payment",
  Info = "info",
  TabDetails = "tab_details",
  Error = "error",
}

const OnpageWidget: FC<{
  service: OnpageWidgetService;
}> = ({ service }) => {
  const [state, send] = useActor(service);

  useShortcut(() => send("CANCEL"), { key: "Escape" });

  useEffect(() => {
    initi18n(state.context.localeCode);
  }, [state.context.localeCode]);

  const isBackDropVisible = state.hasTag("backdrop");
  const isSubscriptions = state.context.isSubscriptionEnabled;
  const isTestMode = !!state.context.merchant.clientId.includes("test");

  const { tab, tabPaid, closedTab, currency, defaultTabLimit } = state.context;
  const pendingAmount = tab
    ? state.context.selectedOffering
      ? state.context.selectedOffering?.price.amount + tab.total
      : tab.total
    : state.context.selectedOffering?.price.amount;

  const tabLimit = useMemo(() => {
    if (tab) {
      return tab.limit;
    } else if (tabPaid || closedTab) {
      return currency.tabLimit;
    } else {
      return currency.firstTabLimit ?? defaultTabLimit;
    }
  }, [tab, closedTab, tabPaid, currency, defaultTabLimit]);

  const tabSummary = {
    currency: state.context.currency,
    firstTabLimit: state.context.currency.firstTabLimit,
    limit: tabLimit,
    amount: tab?.total || 0,
    pendingAmount,
  };

  const getPickerState = () => {
    const pickerState: PickerState | null =
      state.matches("buying.pickingOffering") || state.matches("buyingNow")
        ? PickerState.Pick
        : state.matches("buying.completed")
        ? PickerState.Completed
        : state.matches("buying.completedAfterLogin")
        ? PickerState.CompletedAfterLogin
        : state.matches("buying.processing")
        ? PickerState.Processing
        : state.hasTag("loading")
        ? PickerState.Loading
        : null;
    if (!pickerState) {
      throw new Error(
        `Could not determine picker state for state value: ${state.toStrings()}`
      );
    }
    return pickerState;
  };

  const getBlockerState = () => {
    const blockerState: BlockerState | null = state.matches(
      "buyingSimplified.startAuth"
    )
      ? BlockerState.Auth
      : state.matches("buyingSimplified.payment")
      ? BlockerState.Payment
      : null;
    return blockerState;
  };

  const getOffpagePaymentState = () => {
    const offpagePaymentState = state.matches(
      "buying.payment.awaitingUserConfirmation"
    )
      ? OffpagePaymentState.AwaitingUserConfirmation
      : state.matches("buying.payment.awaitingPaymentCompletion")
      ? OffpagePaymentState.AwaitingPaymentCompletion
      : state.matches("buying.payment.completed")
      ? OffpagePaymentState.Completed
      : null;
    if (!offpagePaymentState) {
      throw new Error("Could not determine offpage payment state");
    }
    return offpagePaymentState;
  };

  const getOnpageWidgetState = () =>
    state.hasTag("inactive")
      ? OnpageWidgetState.Inactive
      : state.matches("buying.payment")
      ? OnpageWidgetState.OffpagePayment
      : state.matches("buying") || state.matches("buyingNow")
      ? OnpageWidgetState.OfferingPicker
      : state.matches("buyingSimplified")
      ? OnpageWidgetState.SimplifiedFlow
      : state.matches("info")
      ? OnpageWidgetState.Info
      : state.matches("tabDetails")
      ? OnpageWidgetState.TabDetails
      : OnpageWidgetState.Error;

  const getContent = (onpageWidgetState: OnpageWidgetState) => {
    const isLoggedIn = !!state.context.userAuthorized;
    const wasAnonymousWhenSelectedOffering =
      state.context.selectedOfferingAnonymously;

    const onLogin =
      !isLoggedIn || wasAnonymousWhenSelectedOffering
        ? () => send({ type: "LOG_IN", loginAction: "login_click" })
        : undefined;

    return {
      [OnpageWidgetState.Inactive]: () => null,
      [OnpageWidgetState.OfferingPicker]: () => (
        <TSOfferingPicker
          {...tabSummary}
          offerings={state.context.clientConfig?.offerings ?? []}
          merchant={state.context.merchant}
          testMode={isTestMode}
          isSubscriptions={isSubscriptions}
          state={getPickerState()}
          onCancel={() => send("CANCEL")}
          onShowInfo={() => send("SHOW_INFO")}
          onShowTabDetails={
            state.context.tab ? () => send("SHOW_TAB_DETAILS") : undefined
          }
          onShowSubscriptionDetails={(
            isSubscriptionEnabled: boolean,
            discount: number
          ) =>
            send({
              type: "TOGGLE_SUBSCRIPTIONS",
              data: { isSubscriptionEnabled, discount },
            })
          }
          onPickOffering={(offering) =>
            send({ type: "PICK_OFFERING", data: { offering } })
          }
          onLogIn={onLogin}
          onSignUp={() => send("SIGN_UP")}
          onBack={() => send("BACK")}
          selectedOffering={state.context.selectedOffering}
          userWasLoggedOut={!!state.context.loggedOutBecauseOfAnError}
          userAccessValidTo={state.context.userAccessValidTo}
          user={state.context.user}
        />
      ),
      [OnpageWidgetState.SimplifiedFlow]: () => (
        <TSBlocker
          currency={state.context.currency}
          testMode={isTestMode}
          state={getBlockerState()}
          onRetry={() =>
            send(
              getBlockerState() === BlockerState.Auth
                ? "SIGN_UP"
                : "START_OFFPAGE_PAYMENT"
            )
          }
        />
      ),
      [OnpageWidgetState.OffpagePayment]: () => (
        <TSOffpagePayment
          {...state.context}
          {...tabSummary}
          isSubscriptions={isSubscriptions}
          discount={state.context.discount}
          state={getOffpagePaymentState()}
          onShowInfo={() => send("SHOW_INFO")}
          onShowTabDetails={() => send("SHOW_TAB_DETAILS")}
          onCancel={() => send("CANCEL")}
          onConfirm={() => send("START_OFFPAGE_PAYMENT")}
          onBackawayOfferAccept={() => send("ACCEPT_BACKAWAY_OFFER")}
        />
      ),
      [OnpageWidgetState.Info]: () => (
        <TSTabInfo
          {...tabSummary}
          testMode={isTestMode}
          onBack={() => send("BACK")}
        />
      ),
      [OnpageWidgetState.TabDetails]: () => (
        <TSTabDetails
          onBack={() => send("BACK")}
          tab={state.context.tab}
          currency={state.context.currency}
          testMode={isTestMode}
          userPortalBaseUrl={new URL(state.context.api.userPortalBaseUrl)}
        />
      ),
      [OnpageWidgetState.Error]: () => (
        <TSError
          currency={state.context.currency.isoCode}
          testMode={isTestMode}
          error={state.context.api.error}
          onBack={() => send("BACK")}
        />
      ),
    }[onpageWidgetState]();
  };

  const onpageWidgetState = getOnpageWidgetState();
  if (onpageWidgetState === OnpageWidgetState.Error) {
    cowConsole.log("Error state", { state });
  }
  const items = [
    {
      key: onpageWidgetState,
      content: getContent(onpageWidgetState),
    },
  ];
  const transitions = useTransition(items, (item) => item.key, {
    from: { transform: "translate3d(0, 100%, 0)" },
    enter: { transform: "translate3d(0, 0, 0)" },
    leave: { transform: "translate3d(0, 100%, 0)" },
    config: springConfig,
  });

  const isLeaveBehindWidgetActive = true;

  // Prevents body scroll when the COW is open
  const bodyElement =
    document && document.querySelectorAll<HTMLElement>("body")[0];
  if (isBackDropVisible) {
    bodyElement["style"].overflow = "hidden";
  } else {
    bodyElement["style"].overflow = "visible";
  }

  return (
    <>
      {isBackDropVisible && (
        <BackDrop
          onClick={() => send("CANCEL")}
          data-testid="widget-backdrop"
        ></BackDrop>
      )}
      {isLeaveBehindWidgetActive && state.context.leaveBehindMachineRef && (
        <>
          <GlobalStyles />
          <LeaveBehindWidget service={state.context.leaveBehindMachineRef} />
        </>
      )}
      {transitions.map(
        ({ item, key, props: animatedProps }) =>
          item.content && (
            <>
              <GlobalStyles />
              <AnimatedContainer
                key={key}
                style={animatedProps}
                onClick={() => send("CANCEL")}
                role="dialog"
              >
                <FocusTrap
                  active={isBackDropVisible}
                  focusTrapOptions={{
                    initialFocus: false,
                    checkCanFocusTrap: () =>
                      isBackDropVisible ? Promise.resolve() : Promise.reject(),
                  }}
                >
                  <div onClick={(e) => e.stopPropagation()}>{item.content}</div>
                </FocusTrap>
              </AnimatedContainer>
            </>
          )
      )}
    </>
  );
};

export default OnpageWidget;
