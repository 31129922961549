import { CurrencyResponse } from "../../../tapper-browser-client";
import { defaultLocaleCode } from "./";
import { formatPrice } from "@getsupertab/price-utils";

export function formattedPrice({
  amount,
  currency,
  localeCode,
  showZeroCents = false,
}: {
  amount: number;
  currency: CurrencyResponse;
  localeCode: string;
  showZeroCents?: boolean;
}) {
  return formatPrice({
    amount,
    currency: currency.isoCode,
    localeCode,
    baseUnit: currency.baseUnit,
    showZeroFractionDigits: showZeroCents,
  });
}

export function formattedDate(
  date: Date,
  localeCode: string = defaultLocaleCode
) {
  return new Intl.DateTimeFormat(localeCode, {
    month: "short",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: false,
  }).format(date);
}

export function timeFromNow(
  date: Date,
  localeCode: string = defaultLocaleCode
) {
  const units = {
    year: 24 * 60 * 60 * 1000 * 365,
    month: (24 * 60 * 60 * 1000 * 365) / 12,
    day: 24 * 60 * 60 * 1000,
    hour: 60 * 60 * 1000,
    minute: 60 * 1000,
    second: 1000,
  };

  const rtf = new Intl.RelativeTimeFormat(localeCode, {
    numeric: "auto",
  });

  const elapsed = date.getTime() - new Date().getTime();

  // "Math.abs" accounts for both "past" & "future" scenarios
  for (const u in units) {
    if (Math.abs(elapsed) > units[u as keyof typeof units] || u === "second") {
      return rtf.format(
        Math.round(elapsed / units[u as keyof typeof units]),
        u as Intl.RelativeTimeFormatUnit
      );
    }
  }
}
