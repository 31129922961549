import styled from "@emotion/styled";
import { Colors, FlexCol } from "../../../styles/GlobalStyles";

export const ButtonsContainer = styled.div`
  ${FlexCol}
  gap: 16px;

  button {
    width: 100%;
    margin-bottom: 18px;
  }
`;

export const BackawayOffer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const BackawaySeparator = styled.div`
  font-weight: 700;
  color: ${Colors.gray};
  text-align: center;
`;
