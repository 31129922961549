import React from "react";

const Close = () => (
  <svg
    style={{ width: "13px", height: "13px", margin: "4px" }}
    width="13"
    height="13"
    viewBox="0 0 13 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.2581 1.23456L11.0235 0L6.12903 4.89447L1.23456 0L0 1.23456L4.89447 6.12903L0 11.0235L1.23456 12.2581L6.12903 7.36359L11.0235 12.2581L12.2581 11.0235L7.36359 6.12903L12.2581 1.23456Z"
      fill="#000"
    />
  </svg>
);

export default Close;
