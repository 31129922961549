import React, { useEffect, useCallback, useRef } from "react";
import { RemainingTime } from "./types";
import { useTranslation } from "react-i18next";

const useRemainingTime = (validTo: Date | undefined, accessTime: number) => {
  const { t } = useTranslation();
  const translatedAbbrs = {
    seconds: t("timeAbbr.seconds"),
    minutes: t("timeAbbr.minutes"),
    hours: t("timeAbbr.hours"),
    days: t("timeAbbr.days"),
  };
  const validToDate = validTo ? new Date(validTo).getTime() : 0;
  const [remainingTime, setRemainingTime] = React.useState<RemainingTime>([
    { time: 0, label: translatedAbbrs.minutes },
    { time: 0, label: translatedAbbrs.seconds },
  ]);
  const [timePercentage, setTimePercentage] = React.useState<number>(0);

  const calculateRemainingTime = useCallback(() => {
    const dateNow = new Date().getTime();
    const timeDiff = validToDate - dateNow;
    const secondInMiliSeconds = 1000;
    const minuteInMiliSeconds = secondInMiliSeconds * 60;
    const hourInMiliSeconds = minuteInMiliSeconds * 60;
    const dayInMiliSeconds = hourInMiliSeconds * 24;

    if (timeDiff && timeDiff > 0) {
      const daysLeft = Math.trunc(timeDiff / dayInMiliSeconds);

      const hoursLeft = Math.trunc(
        (timeDiff % dayInMiliSeconds) / hourInMiliSeconds
      );

      const minutesLeft = Math.trunc(
        (timeDiff % hourInMiliSeconds) / minuteInMiliSeconds
      );

      const secondsLeft = Math.trunc(
        (timeDiff % minuteInMiliSeconds) / secondInMiliSeconds
      );
      const remainingTimeInSeconds = timeDiff / 1000;

      setTimePercentage(
        Math.floor((remainingTimeInSeconds * 100) / accessTime)
      );

      setRemainingTime([
        ...(daysLeft ? [{ time: daysLeft, label: translatedAbbrs.days }] : []),
        ...(hoursLeft
          ? [{ time: hoursLeft, label: translatedAbbrs.hours }]
          : []),
        ...(daysLeft < 1 && minutesLeft
          ? [{ time: minutesLeft, label: translatedAbbrs.minutes }]
          : []),
        ...(hoursLeft < 1
          ? [{ time: secondsLeft, label: translatedAbbrs.seconds }]
          : []),
      ]);
    } else {
      setRemainingTime([{ time: 0, label: translatedAbbrs.seconds }]);
    }
  }, [accessTime, validToDate]);

  const calculateRemainingTimeInterval = useRef<any>();

  useEffect(() => {
    if (!calculateRemainingTimeInterval.current) {
      calculateRemainingTime();
    }

    calculateRemainingTimeInterval.current = setInterval(
      () => calculateRemainingTime(),
      1000
    );

    return () => {
      clearInterval(calculateRemainingTimeInterval.current);
    };
  });

  return { remainingTime, timePercentage };
};

export default useRemainingTime;
