import styled from "@emotion/styled";
import {
  Colors,
  FlexGrow,
  ParagraphCss,
  TextSmall,
} from "../../../styles/GlobalStyles";

export const HeaderStyled = styled.header`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 24px;

  p {
    ${ParagraphCss}
    line-height: 1.25;
  }
`;

export const Content = styled.div`
  display: flex;
  ${FlexGrow}
  flex-direction: column;
  gap: 16px;
  align-self: center;

  text-align: center;

  p {
    ${ParagraphCss}
    line-height: 1.25;
    margin: 0;
  }

  // contentPosition="above"
  &:first-child {
    padding: 8px 0 8px;
  }

  // contentPosition="below"
  &:last-child {
    padding: 0 24px;
  }
`;

export const TabContainer = styled.div`
  align-items: start;
  border-radius: 10px;
  border: 1px solid #d9dee0;
  display: grid;
  grid-template-columns: minmax(auto, max-content) 1fr;
  gap: 10px 20px;
  width: 100%;
  min-height: 130px;
  padding: 24px;
`;

export const TabCopy = styled.span`
  color: ${Colors.lightblack};
  ${TextSmall}
  font-weight: 400;
  letter-spacing: 0.01em;
  line-height: 19px;
  text-align: left;

  > button {
    vertical-align: text-bottom;
  }
`;

export const TabActions = styled.div`
  width: 100%;
  grid-column: span 2;
  button {
    width: 100%;
`;
