import React from "react";
import { ArcStyled } from "./styled";

export const Arc: React.FC<{
  startAngle: number;
  endAngle: number;
  strokeWidth: number;
  fillRatio?: number;
  clockwise?: boolean;
  colorWay?: string;
  variant?: "full" | "compact";
}> = ({
  startAngle,
  endAngle,
  strokeWidth,
  fillRatio = 1,
  clockwise = true,
  variant = "full",
  colorWay = "primary",
}) => {
  const width = 100 + strokeWidth;
  const height = 100 + strokeWidth;
  const startPoint = polarToCartesian(width / 2, height / 2, 50, startAngle);
  const endPoint = polarToCartesian(width / 2, height / 2, 50, endAngle);
  const sweep = clockwise ? 1 : 0;
  const largeArc = sweep ^ (endAngle - startAngle < 180 ? 1 : 0);
  const radius = 50;
  const circumference = 2 * Math.PI * radius;
  const angle = clockwise
    ? Math.abs(endAngle - startAngle)
    : 360 - Math.abs(endAngle - startAngle);
  const arcLength = circumference * (angle / 360) * fillRatio;
  return (
    <ArcStyled
      xmlns="http://www.w3.org/2000/svg"
      viewBox={`0 0 ${width} ${height}`}
      stroke="black"
      strokeWidth={strokeWidth}
      fill="none"
      strokeLinecap="round"
      style={{
        strokeDasharray: circumference,
        strokeDashoffset: circumference - arcLength,
        transition: "stroke-dashoffset .5s ease-in-out",
      }}
      colorWay={colorWay}
      data-variant={variant}
    >
      <path
        d={`
          M ${startPoint.x} ${startPoint.y}
          A 50 50 0 ${largeArc} ${sweep} ${endPoint.x} ${endPoint.y}`}
      />
    </ArcStyled>
  );
};

export default Arc;

function polarToCartesian(
  centerX: number,
  centerY: number,
  radius: number,
  angleInDegrees: number
) {
  const angleInRadians = ((angleInDegrees - 90) * Math.PI) / 180.0;
  return {
    x: centerX + radius * Math.cos(angleInRadians),
    y: centerY + radius * Math.sin(angleInRadians),
  };
}
