import styled from "@emotion/styled";
import { Colors } from "../../../styles/GlobalStyles";
import { ColorWayStyleProps } from "../../../styles/types";

export const ArcStyled = styled.svg<ColorWayStyleProps>`
  position: absolute;
  width: 100%;
  height: 100%;
  stroke: currentColor;
  color: ${(props) =>
    props.colorWay ? Colors[props.colorWay] : Colors.primary};

  &[data-variant="compact"] {
    width: 80px;
    height: 80px;
  }
`;
