import { PropsWithChildren } from "react";
import { Back } from "./styled";

const BackIcon = () => (
  <svg
    width="9"
    height="15"
    viewBox="0 0 9 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.25 7.5C0.25 7.125 0.375 6.875 0.625 6.625L6.875 0.375C7.375 -0.125 8.125 -0.125 8.625 0.375C9.125 0.875 9.125 1.625 8.625 2.125L3.25 7.5L8.625 12.875C9.125 13.375 9.125 14.125 8.625 14.625C8.125 15.125 7.375 15.125 6.875 14.625L0.625 8.375C0.375 8.125 0.25 7.875 0.25 7.5Z"
      fill="#989898"
    />
  </svg>
);

function BackButton({ onBack }: PropsWithChildren<{ onBack: () => void }>) {
  return (
    <Back data-testid="back-button" onClick={onBack} aria-label="Back">
      <BackIcon />
    </Back>
  );
}

export { BackButton };
