import React, { FC, useState } from "react";
import { Trans, useTranslation } from "../../../util/i18n";
import RemainingTime from "../RemainingTime";
import { ContentWrapper, WidgetHeader } from "../styled";
import {
  ViewTabButton,
  TitleCopy,
  WidgetTitle,
  MoreAccessButton,
  ErrorContactMessage,
  ErrorContainer,
  PayNowButton,
} from "./styled";
import WidgetTabInfo from "../WidgetTabInfo";
import { environment } from "../../../../environment";
import {
  CurrencyResponse,
  ItemOffering,
  Merchant,
  Subscription,
  Tab,
} from "../../../../tapper-browser-client";
import { UserAccessDetail } from "../../../statecharts/onpageWidget";
import Button from "../../atoms/Button";
import { TabStatus } from "@getsupertab/tapper-sdk";

interface OverviewProps {
  merchant: Pick<Merchant, "name">;
  onToggleTabDetails: () => void;
  onGetMoreAccessClick: () => void;
  userAccessValidTo?: Date;
  tab?: Tab | null;
  selectedOffering?: ItemOffering;
  subscription?: Subscription;
  defaultTabLimit: number;
  currency: CurrencyResponse;
  accessDetails: UserAccessDetail[];
  state: OverviewState;
  onErrorTryAgainClick: () => void;
  onPayNowClick: () => void;
}

export enum OverviewState {
  Initial = "initial",
  TabDetails = "showingTabDetails",
  Error = "error",
}

const Overview: FC<OverviewProps> = ({
  merchant,
  userAccessValidTo,
  tab,
  selectedOffering,
  defaultTabLimit,
  subscription,
  currency,
  state,
  onToggleTabDetails,
  onGetMoreAccessClick,
  accessDetails,
  onErrorTryAgainClick,
  onPayNowClick,
}) => {
  const { t } = useTranslation();
  const [isAccessFinished, setIsAccessFinished] = useState(false);
  const accessTotalTime = accessDetails.length
    ? accessDetails[0].validTo - accessDetails[0].createdAt
    : 0;
  const isSubscriptionCancelled =
    subscription?.status === "cancel_at_period_end";

  const showViewMyAccountButton =
    state !== OverviewState.Error &&
    !(isAccessFinished && accessDetails.length !== 0) &&
    tab?.status !== TabStatus.Full;
  const showPayNowButton =
    state !== OverviewState.Error && tab?.status === TabStatus.Full;
  const showGetMoreAccess =
    state !== OverviewState.Error &&
    !showViewMyAccountButton &&
    !showPayNowButton;

  const onViewMyTabClick = () => {
    window.open(
      new URL(
        `/?testmode=${tab?.testMode ? "true" : "false"}`,
        environment.userPortalBaseUrl
      ).toString(),
      "_blank"
    );
  };

  const TheTitle = () =>
    state === OverviewState.Error ? (
      <TitleCopy>
        <Trans i18nKey="errorTitle" components={[<br key="breakLine" />]} />
      </TitleCopy>
    ) : isAccessFinished ? (
      <Trans i18nKey="usedUpTimeWidgetTitle" values={{ merchant }} />
    ) : userAccessValidTo && !subscription ? (
      <Trans i18nKey={"accessAllWidgetTitle"} values={{ merchant }} />
    ) : subscription ? (
      <Trans
        i18nKey={
          isSubscriptionCancelled
            ? "subscriptions.cancelledTitle"
            : "subscriptions.subscriptionWidgetTitle"
        }
        values={{ merchant }}
      />
    ) : (
      <TitleCopy data-testid="merchant-name">{merchant.name}</TitleCopy>
    );

  return (
    <>
      <WidgetHeader>
        <WidgetTitle data-testid="lbw-overview-title">
          <TheTitle />
        </WidgetTitle>
        {userAccessValidTo && (
          <RemainingTime
            validTo={userAccessValidTo}
            accessTime={accessTotalTime}
            onTimeEnd={() => setIsAccessFinished(true)}
          />
        )}
        {showGetMoreAccess && (
          <MoreAccessButton
            testId="get-more-access"
            key="getMoreAccess"
            onClick={onGetMoreAccessClick}
            size="small"
          >
            <Trans i18nKey="getMoreAccess" />
          </MoreAccessButton>
        )}
      </WidgetHeader>
      <ContentWrapper data-testid="overview-container">
        {state === OverviewState.Error ? (
          <ErrorContainer>
            <p>
              <Trans i18nKey="errorParagraph" />
            </p>
            <Button onClick={onErrorTryAgainClick}>{t("tryAgain")}</Button>
            <ErrorContactMessage>
              <Trans
                i18nKey="contactSupport"
                components={[
                  // eslint-disable-next-line jsx-a11y/anchor-has-content
                  <a
                    href={environment.customerSupportUrl}
                    key="customerSupport"
                  />,
                ]}
              />
            </ErrorContactMessage>
          </ErrorContainer>
        ) : (
          <WidgetTabInfo
            selectedOffering={selectedOffering}
            tab={tab}
            defaultTabLimit={defaultTabLimit}
            currency={currency}
            showDetails={state === OverviewState.TabDetails}
            onTabClick={onToggleTabDetails}
            merchant={merchant}
          />
        )}

        {showViewMyAccountButton && (
          <ViewTabButton
            testId="lbw-view-my-account"
            key="link"
            onClick={onViewMyTabClick}
            weight="bold"
            size="small"
          >
            <Trans i18nKey="viewMyAccount" />
          </ViewTabButton>
        )}

        {showPayNowButton && (
          <PayNowButton
            testId="lbw-pay-now-button"
            size="small"
            onClick={onPayNowClick}
          >
            <Trans i18nKey="payNow" />
          </PayNowButton>
        )}
      </ContentWrapper>
    </>
  );
};

export default Overview;
