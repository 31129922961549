import styled from "@emotion/styled";
import { css } from "@emotion/react";
import {
  Colors,
  FlexCol,
  FlexGrow,
  OverflowHidden,
  ParagraphCss,
  ScreenSizes,
  TextSmall,
} from "../../../styles/GlobalStyles";
import { FooterStyled } from "../../atoms/DefaultFooter/styled";

export const BottomSheetStyled = styled.section`
  ${OverflowHidden}
  width: 384px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  background: #fff;
  overflow: auto;

  @media (min-width: ${ScreenSizes.sm}) {
    max-width: 384px;
    border-bottom-right-radius: 8px;
    border-bottom-left-radius: 8px;
  }
`;

const TitleStyledCss = css`
  font-weight: 400;
  line-height: 22px;
  margin: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 46px 48px 20px;
  color: ${Colors.lightblack};
`;

export const TitleStyled = styled.div`
  ${TitleStyledCss}
  font-weight: 500;
`;

export const TitleStyledHeading = TitleStyled.withComponent("h1");

export const SubTitleStyledHeading = styled.h2<{
  textAlign?: "center" | "right" | "left";
}>`
  margin: 0;
  text-align: ${({ textAlign }) => textAlign && textAlign};
  ${TextSmall}
  color: ${Colors.darkgray};
  strong {
    color: ${Colors.primary};
  }
`;

export const SupertabLink = styled.a`
  ${FooterStyled}
  text-decoration: underline;
`;

export const BodyContainer = styled.div`
  ${FlexGrow}
  overflow: auto;
`;

export const BodyChildren = styled.div<{ gap?: string }>`
  ${FlexCol}
  gap: 16px;
  margin: 20px 16px;
  [data-variant="compact"] & {
    margin: 0 16px;
  }
  [data-extraMarginTop] & {
    margin-top: 12px;
  }
  ${({ gap }) =>
    gap &&
    css`
      gap: ${gap};
    `}
  p {
    ${ParagraphCss}
    margin: 0;
    line-height: 1.25;
  }
`;

export const Header = styled.header`
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  text-align: center;
  min-height: 64px;
  flex-direction: column;
  position: relative;

  @media (min-width: ${ScreenSizes.md}) {
    min-height: 0;
  }
`;

export const TestModeBadge = styled.div`
  background-color: ${Colors.warn};
  color: ${Colors.lightblack};
  padding: 6px 53px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  ${TextSmall}
  width: 100%;
  text-align: center;
  position: relative;
`;

export const Wrapper = styled.div`
  ${FlexCol}
  position: relative;
`;

export const InfoIconSVG = styled.svg`
  display: inline-block;
  margin-right: 15px;
  width: 18px;
  height: 18px;
  fill: none;
  & path {
    fill: ${Colors.lightblack};
    fill-opacity: 0.9;
  }
`;
