import {
  AuthenticationMachineContext,
  AuthenticationMachineEvents,
} from "./types";
import { decodeJwt } from "../../util/jwt";

import { LaterpayBrowserClient, User } from "../../../tapper-browser-client";

import { cowConsole } from "../../../util/cowConsole";

const getApi = (ctx: AuthenticationMachineContext) =>
  new LaterpayBrowserClient(
    ctx.clientId,
    ctx.authBaseUrl,
    ctx.ssoBaseUrl,
    ctx.tapiBaseUrl,
    ctx.refreshToken,
    ctx.accessToken,
    ctx.authUrl,
    ctx.tokenUrl
  );

export async function authorizeClient(
  ctx: AuthenticationMachineContext,
  ev: AuthenticationMachineEvents
): Promise<{
  authCode: string;
  codeVerifier: string;
}> {
  cowConsole.log("Authorizing client");

  if (ev.type !== "START_AUTH") {
    throw new Error("Invoked authorizeClient with event other than START_AUTH");
  }

  const { authCode, codeVerifier, ssoAbandoned } = await getApi(
    ctx
  ).startAuthFlow(
    new URL(ctx.redirectUri),
    ev.screenHint,
    ev.loginAction,
    ctx.localeCode
  );
  cowConsole.log("Received auth result", {
    authCode,
    codeVerifier,
    ssoAbandoned,
  });

  if (ssoAbandoned) {
    return Promise.reject({
      screenHint: ev.screenHint,
      loginAction: ev.loginAction,
    });
  }
  return {
    authCode,
    codeVerifier,
  };
}

export async function fetchTokens(
  ctx: AuthenticationMachineContext,
  evt: AuthenticationMachineEvents
) {
  if (evt.type !== "done.invoke.authorizeClient") {
    throw new Error(
      "fetchTokens need to be called with 'done.invoke.authorizeClient'"
    );
  }
  if (!evt.data.authCode) {
    throw new Error("Cannot fetch tokens without auth code");
  }
  if (!evt.data.codeVerifier) {
    throw new Error("Cannot fetch tokens without codeVerifier");
  }
  if (!ctx.redirectUri) {
    throw new Error("Cannot fetch tokens without redirect URI");
  }

  cowConsole.log("Fetching tokens using PKCE", ctx, evt);

  const { accessToken, refreshToken } = await getApi(ctx).fetchTokens(
    evt.data.authCode,
    evt.data.codeVerifier,
    ctx.redirectUri
  );
  cowConsole.log("Fetch tokens result:", { accessToken, refreshToken });
  return { accessToken, refreshToken };
}

export async function refreshTokens(ctx: AuthenticationMachineContext) {
  cowConsole.log("Refreshing tokens", ctx);
  const { accessToken, refreshToken } = await getApi(ctx).refreshTokens();
  cowConsole.log("Refresh tokens result:", { accessToken, refreshToken });
  return { accessToken, refreshToken };
}

export async function identifyUser(
  ctx: AuthenticationMachineContext
): Promise<User | void> {
  const { accessToken } = ctx;

  if (!accessToken) {
    throw new Error("Cannot identify user without accessToken");
  }

  const token = decodeJwt(accessToken);

  const client = new LaterpayBrowserClient(
    ctx.clientId,
    ctx.authBaseUrl,
    ctx.ssoBaseUrl,
    ctx.tapiBaseUrl,
    ctx.refreshToken,
    ctx.accessToken,
    ctx.authUrl,
    ctx.tokenUrl
  );

  const user = await client.getUser(token.sub);

  return user;
}
