import styled from "@emotion/styled";
import { ScreenSizes, TextMedium, TextSmall } from "../../styles/GlobalStyles";
import { OptionalTabLink } from "../molecules/DefaultTabCopyChildren/styled";
import { BackDrop } from "../widgets/styled";
import { Colors } from "../../styles/GlobalStyles/styled";

export const WidgetTriggerContainer = styled.div<{ position?: string }>`
  position: ${({ position }) => (position === "top" ? "absolute" : "fixed")};
  bottom: ${({ position }) => (position === "bottom" ? "60px" : null)};
  top: ${({ position }) => (position === "top" ? "-44px" : null)};
  right: 7px;
  display: flex;
  gap: 11px;
  align-items: center;

  @media (min-width: ${ScreenSizes.xs}) {
    bottom: 60px;
  }
`;

export const ThankYouContainer = styled.div`
  ${TextSmall}
  position: absolute;
  right: 50px;
  background: ${Colors.white};
  color: ${Colors.lightblack};
  width: 288px;
  line-height: 20px;
  padding: 13px 16px;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 6px;
`;

export const WidgetContainerStory = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: block;
  background: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px 20px 0 0;
  display: block;
  text-align: center;
  padding: 0 26px;

  @media (min-width: ${ScreenSizes.xs}) {
    width: 348px;
    bottom: 108px;
    right: 10px;
    border-radius: 8px;
  }
`;

export const WidgetContainer = styled.div`
  width: 100%;
  box-sizing: border-box;
  display: block;
  background: white;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.25);
  border-radius: 20px 20px 0 0;
  display: block;
  position: fixed;
  bottom: 0;
  right: 0;
  z-index: 2147483647;
  text-align: center;
  padding: 0 26px;

  @media (min-width: ${ScreenSizes.xs}) {
    width: 348px;
    bottom: 108px;
    right: 10px;
    border-radius: 8px;
  }
`;

export const WidgetHeader = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 26px;
  padding-top: 26px;
`;

export const TitleWrapper = styled.div`
  ${TextMedium}
  margin-bottom: 12px;
`;

export const CopyWrapper = styled.div`
  ${TextSmall}
  line-height: 20px;
  padding: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const ButtonContainer = styled.div`
  padding: 0 36px;
  & button {
    width: 100%;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 26px;
  width: 100%;
  max-width: 296px;
  margin: 0 auto;
`;

export const FooterWrapper = styled.div`
  margin-top: 6px;
`;

export const WidgetTitle = styled.span`
  ${TextSmall}
`;

export const MyTabLink = styled(OptionalTabLink)`
  ${TextSmall}
  line-height: 20px;
  font-weight: 700;
`;

export const LoadingContainer = styled.div`
  padding: 26px 0 0;
`;

export const WidgetBackDrop = styled(BackDrop)`
  opacity: 0.5;
  @media (min-width: ${ScreenSizes.xs}) {
    display: none;
`;
