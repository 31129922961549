import { useEffect, useCallback } from "react";

export const useShortcut = (
  callback: (evt: KeyboardEvent) => void,
  {
    ctrlKey = false,
    key,
  }: {
    ctrlKey?: boolean;
    key: string;
  }
) => {
  const handler = useCallback(
    (evt: KeyboardEvent) => {
      if (
        (evt.metaKey === ctrlKey || evt.ctrlKey === ctrlKey) &&
        evt.key === key
      ) {
        callback(evt);
      }
    },
    [ctrlKey, key, callback]
  );

  useEffect(() => {
    // attach the event listener
    document.addEventListener("keydown", handler);

    // remove the event listener
    return () => {
      document.removeEventListener("keydown", handler);
    };
  }, [handler]);
};

export default useShortcut;
