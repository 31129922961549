import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Colors, TextSmall } from "../../../styles/GlobalStyles";

export const Timer = styled.span`
  ${TextSmall}
  color: black;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  gap: 9px;
  align-items: center;
  margin: auto;
  padding: 0 22px;
`;

export const WidgetWrapper = styled.button<{ hasTimer?: boolean }>`
  width: 39px;
  height: 39px;
  background: ${Colors.white};
  border: 1px solid #d9dee0;
  border-radius: 100px;
  padding: 8px;
  position: relative;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.1);

  &:hover {
    cursor: pointer;
  }
  ${({ hasTimer }) =>
    hasTimer &&
    css`
    &:hover {
    transition: all 0.5s cubic-bezier(0.68, 0, 0.265, 1.55);
    color: black;
    background: ${Colors.white};
    border: 1px solid #d9dee0;
    width: fit-content;`}
  }
`;

export const InnerTrigger = styled.div<{ hasTimer: boolean }>`
  width: ${({ hasTimer }) => (hasTimer ? "85px" : "45px")};
  height: 45px;
  position: absolute;
  top: 50%;
  right: 0;
  transform: translate(0, -50%);
  border-radius: 100px;
  background: none;
`;
