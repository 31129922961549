import i18n from "i18next";
import { useTranslation, initReactI18next, Trans } from "react-i18next";
import { formattedPrice, formattedDate, timeFromNow } from "./formatters";
import { parseTimeDelta } from "../misc";
import resources from "./languages";

export * from "./formatters";

export const defaultLocaleCode = "en";

export const initi18n = (localeCode: string) => {
  i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .init({
      resources,
      lng: localeCode,
      fallbackLng: defaultLocaleCode,

      interpolation: {
        escapeValue: false,
      },

      react: {
        transKeepBasicHtmlNodesFor: ["br", "wbr", "strong", "i", "p"],
      },
    });

  i18n.services.formatter?.add("price", (amount, localeCode, options) => {
    return formattedPrice({
      ...options,
      amount,
      localeCode,
    });
  });
  i18n.services.formatter?.add("formattedDate", (value, lng) => {
    return formattedDate(value, lng);
  });
  i18n.services.formatter?.add("timeDelta", (delta: string) => {
    const timeDelta = parseTimeDelta(delta);
    return i18n.t(`translation:timeDelta.${timeDelta.unit}`, {
      count: timeDelta.count,
    });
  });
  i18n.services.formatter?.add("timeFromNow", (value, lng) => {
    return timeFromNow(value, lng) ?? "";
  });
};

export { useTranslation, Trans };
