import "./capture-errors";
import CtoOnpageWidget from "./cto-onpage-widget";
import { environment } from "./environment";
import SupertabMonetizationProvider from "./supertab-monetization-provider";
import {
  DestroyReasonEnumDefaultType,
  EntitlementTypeEnumDefaultType,
  MonetizationPortalEnumDefaultType,
  MonetizationProvider,
  MonetizationRecurrenceEnumDefaultType,
  UserEntitlementStateEnumDefaultType,
} from "./supertab-monetization-provider.types";

// Expose global event API
const eventNames = ["cow.event", environment.widgetCustomEventName];

eventNames.forEach((eventName) => {
  const event = new CustomEvent(eventName, {
    detail: {
      type: "ready",
      CtoOnpageWidget: CtoOnpageWidget,
      Widget: CtoOnpageWidget,
    },
  });

  window.dispatchEvent(event);
});

// Register monetization provider with Google Funding Choices

declare global {
  interface Window {
    googlefc: {
      monetization: {
        providerRegistry: Map<string, MonetizationProvider>;
        MonetizationPortalEnum: typeof MonetizationPortalEnumDefaultType;
        UserEntitlementStateEnum: typeof UserEntitlementStateEnumDefaultType;
        EntitlementTypeEnum: typeof EntitlementTypeEnumDefaultType;
        MonetizationRecurrenceEnum: typeof MonetizationRecurrenceEnumDefaultType;
        DestroyReasonEnum: typeof DestroyReasonEnumDefaultType;
      };
    };
  }
}

// Provide defaults for the global googlefc object
window.googlefc = window.googlefc || {};
window.googlefc.monetization = window.googlefc.monetization || {};
window.googlefc.monetization.providerRegistry =
  window.googlefc.monetization.providerRegistry || new Map();
// Provide enum defaults
window.googlefc.monetization.MonetizationPortalEnum =
  window.googlefc.monetization.MonetizationPortalEnum ||
  MonetizationPortalEnumDefaultType;
window.googlefc.monetization.UserEntitlementStateEnum =
  window.googlefc.monetization.UserEntitlementStateEnum ||
  UserEntitlementStateEnumDefaultType;
window.googlefc.monetization.EntitlementTypeEnum =
  window.googlefc.monetization.EntitlementTypeEnum ||
  EntitlementTypeEnumDefaultType;
window.googlefc.monetization.MonetizationRecurrenceEnum =
  window.googlefc.monetization.MonetizationRecurrenceEnum ||
  MonetizationRecurrenceEnumDefaultType;
window.googlefc.monetization.DestroyReasonEnum =
  window.googlefc.monetization.DestroyReasonEnum ||
  DestroyReasonEnumDefaultType;

// Register SupertabMonetizationProvider with googlefc
window.googlefc.monetization.providerRegistry.set(
  "supertab",
  new SupertabMonetizationProvider()
);
