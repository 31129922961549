import { environment } from "../environment";

const searchParams = new URL(window.location.href).searchParams;
const isEnabled =
  searchParams.has("cow-enable-logging") || environment.env !== "prod";

export const cowConsole = {
  log: (...args: any[]) => {
    isEnabled && console.log(...args);
  },

  warn: (...args: any[]) => {
    isEnabled && console.warn(...args);
  },

  info: (...args: any[]) => {
    isEnabled && console.info(...args);
  },

  error: (...args: any[]) => {
    isEnabled && console.error(...args);
  },
};
