import React, { ButtonHTMLAttributes, DOMAttributes } from "react";
import { Thumb, ToggleButton } from "./styled";
import { useTheme } from "@emotion/react";

export interface ToggleProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  checked?: boolean;
  onToggle: DOMAttributes<HTMLButtonElement>["onClick"];
  testId?: string;
}

const Toggle = ({
  className,
  disabled = false,
  checked = false,
  onToggle,
  testId,
  ...toggleProps
}: ToggleProps) => {
  const theme = useTheme();

  return (
    <ToggleButton
      disabled={disabled}
      onClick={onToggle}
      role="toggle"
      type="button"
      checked={checked}
      data-testid={testId}
      theme={theme}
      {...toggleProps}
    >
      <Thumb />
    </ToggleButton>
  );
};

export default Toggle;
