import styled from "@emotion/styled";
import {
  Colors,
  ParagraphCss,
  TextMedium,
  TextTiny,
} from "../../../styles/GlobalStyles";
import Price from "../../atoms/Price";
import { StyledTabIndicator } from "../../organisms/TabIndicator/styled";
import { ReactComponent as ChevronIcon } from "../../../../svg/chevron.svg";

export const StoryContainer = styled.div`
  width: 296px;
`;

export const TabContainer = styled.div`
  box-sizing: border-box;
  border-radius: 10px;
  border: 1px solid #d9dee0;
  width: 100%;
  padding: 14px 16px;
  background: transparent;
  margin: auto;
`;

export const TabOverview = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 15px;
  background: transparent;
`;

export const HeaderStyled = styled.header`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  p {
    ${ParagraphCss}
    line-height: 1.25;
  }
`;

export const WidgetTabIndicator = styled(StyledTabIndicator)`
  width: 80px;
  height: 80px;
  margin: 0;
  font-size: 15px;
`;

export const TabCopy = styled.button<{ hasItems: boolean }>`
  ${TextMedium}
  color: ${Colors.black};
  font-weight: 700;
  line-height: 20px;
  text-align: center;

  &:hover {
    cursor: ${({ hasItems }) => (hasItems ? "pointer" : "inherit")};
  }
`;

export const TabDetails = styled.div`
  text-align: left;
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 132px;
`;

export const PurchasesList = styled.div<{ scroll: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 10px;
  max-height: 80px;
  overflow-y: ${({ scroll }) => (scroll ? "scroll" : "hidden")};
  padding-right: 14px;
`;

export const PurchaseSubtitle = styled.h3`
  color: ${Colors.textGray};
  font-size: 15px;
  font-weight: 700;
  margin: 18px 0 0;
`;

export const PurchaseItem = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: flex-start;
  line-height: 14px;
`;

export const PriceText = styled(Price)`
  ${TextTiny}
  line-height: 14px;
`;

export const PurchaseText = styled.span<{
  light?: boolean;
  summary?: boolean;
}>`
  ${TextTiny}
  line-height: 14px;
  max-width: ${({ summary }) => (summary ? "130px" : "none")};
  color: ${({ light }) => (light ? Colors.textLightGray : Colors.textGray)};
`;

export const ViewMore = styled.span`
  ${TextTiny}
  color: ${Colors.textLightGray};
  display: flex;
  justify-content: space-between;

  span {
    font-weight: 700;
  }
`;

export const Chevron = styled(ChevronIcon)<{ isOpen: boolean }>`
  transform: ${({ isOpen }) => (isOpen ? "rotate(180deg)" : "rotate(0deg)")};
  display: inline-block;
`;
