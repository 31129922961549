import { cowConsole } from "../util/cowConsole";
import {
  ScreenHint,
  Tab,
  TabServerResponse,
  User,
  LoginAction,
  TabStatus,
  PurchaseOutcome,
} from "./types";
import {
  generateCodeChallenge,
  generateRandomString,
  transformCamelObjToSnake,
  transformSnakeObjToCamel,
} from "./util";

import { Configuration, ItemsApi, PaymentApi } from "@getsupertab/tapper-sdk";

const oauth2Scopes =
  "tab:tab:read tab:purchase:write tab:subscription:read auth:user:read offline_access";

// We need to precompute codeVerifier/codeChallenge pairs so that we don't
// hit the timing limit of popup blockers when we generate OAuth2 PKCE URLs
// TODO: Find a cleaner way to solve this
let precomputedPkceParams: { codeVerifier: string; codeChallenge: string };
function populatePrecomputedPkceParams() {
  const codeVerifier = generateRandomString(64);
  generateCodeChallenge(codeVerifier).then((codeChallenge) => {
    precomputedPkceParams = { codeChallenge, codeVerifier };
  });
}
// Only precompute on the client side
if (typeof window !== "undefined") {
  if (!window.isSecureContext) {
    window.location.href = window.location.href.replace(/^http:/, "https:");
  }
  populatePrecomputedPkceParams();
}

export class LaterpayBrowserClient {
  clientId: string;
  authBaseUrl: string;
  ssoBaseUrl: string;
  tapiBaseUrl: string;
  refreshToken?: string;
  accessToken?: string;
  apiConfig: Configuration;
  authUrl: string;
  tokenUrl: string;

  static tapperClientSessionId = this.generateTapperClientSessionId();

  constructor(
    clientId: string,
    authBaseUrl: string,
    ssoBaseUrl: string,
    tapiBaseUrl: string,
    refreshToken?: string,
    accessToken?: string,
    authUrl?: string,
    tokenUrl?: string
  ) {
    this.clientId = clientId;
    this.authBaseUrl = authBaseUrl;
    this.ssoBaseUrl = ssoBaseUrl;
    this.tapiBaseUrl = tapiBaseUrl;
    this.refreshToken = refreshToken;
    this.accessToken = accessToken;
    this.authUrl = authUrl ?? "/oauth2/auth";
    this.tokenUrl = tokenUrl ?? "/oauth2/token";
    this.apiConfig = new Configuration({
      basePath: this.tapiBaseUrl,
      accessToken: `Bearer ${this.accessToken}`,
      headers: {
        "x-tapper-client-session-id":
          LaterpayBrowserClient.tapperClientSessionId,
      },
    });
  }

  static generateTapperClientSessionId(): string {
    if (!window.crypto?.randomUUID) {
      throw new Error(
        "Failed to create Tapper Client Session ID. `window.crypto.randomUUID` is unavailable. Make sure to run this in a secure context."
      );
    }
    return `cow_session.${window.crypto.randomUUID()}`;
  }

  async precomputedPkceParamsReady(): Promise<void> {
    return new Promise((resolve) => {
      const checkInterval = setInterval(check, 100);
      check();
      function check() {
        if (precomputedPkceParams) {
          clearInterval(checkInterval);
          resolve();
        }
      }
    });
  }

  createAuthUrl(
    redirectUri: URL,
    screenHint: ScreenHint,
    loginAction?: LoginAction,
    languageHint?: string
  ) {
    const { codeVerifier, codeChallenge } = precomputedPkceParams;
    populatePrecomputedPkceParams(); // Will asynchronously generate and store new params for the next time we need them

    const state = window.btoa(
      JSON.stringify({
        origin: window.location.origin,
      })
    );

    const url = new URL(this.authUrl, this.authBaseUrl);

    url.searchParams.set("code_challenge", codeChallenge);
    url.searchParams.set("code_challenge_method", "S256");
    url.searchParams.set("scope", oauth2Scopes);
    url.searchParams.set("response_type", "code");
    url.searchParams.set("state", state);
    url.searchParams.set("client_id", this.clientId);
    url.searchParams.set("redirect_uri", redirectUri.toString());
    url.searchParams.set("audience_hint", "consumer");
    url.searchParams.set("screen_hint", screenHint);
    url.searchParams.set("t_origin", "cow");
    if (loginAction) {
      url.searchParams.set("t_action", loginAction);
    }
    if (languageHint) {
      url.searchParams.set("language_hint", languageHint);
    }
    url.searchParams.set(
      "tapper_client_session_id",
      LaterpayBrowserClient.tapperClientSessionId
    );

    return { url, codeVerifier };
  }

  async startAuthFlow(
    redirectUri: URL,
    screenHint: ScreenHint,
    loginAction?: LoginAction,
    languageHint?: string
  ): Promise<{
    authCode: string;
    scope: string;
    codeVerifier: string;
    ssoAbandoned: boolean;
  }> {
    const { url, codeVerifier } = this.createAuthUrl(
      redirectUri,
      screenHint,
      loginAction,
      languageHint
    );
    const state = url.searchParams.get("state");
    const scope = url.searchParams.get("scope");

    const authWindow = window.open(url.toString(), "ssoWindow");

    const response = {
      authCode: "",
      scope: "",
      codeVerifier: "",
      ssoAbandoned: false,
    };
    let receivedPostMessage = false;

    return new Promise((resolve, reject) => {
      function eventListener(ev: MessageEvent) {
        if (ev.source === authWindow) {
          window.removeEventListener("message", eventListener as EventListener);
          authWindow?.close();
          receivedPostMessage = true;
          cowConsole.log("Received message from child window", ev.data);
          if (state !== ev.data.state) {
            reject(
              new Error(
                `State mismatch: ${JSON.stringify({
                  expectedState: state,
                  receivedState: ev.data.state,
                })}`
              )
            );
          } else if (scope !== ev.data.scope) {
            reject(
              new Error(
                `Scope mismatch: ${JSON.stringify({
                  expectedScope: scope,
                  receivedScope: ev.data.scope,
                })}`
              )
            );
          } else if (!ev.data.authCode) {
            reject(new Error("Auth code is missing"));
          } else {
            const { state, scope, authCode } = ev.data;
            cowConsole.log("Received auth data looks good", {
              state,
              scope,
              authCode,
            });
            resolve({ ...response, authCode, scope, codeVerifier });
          }
        }
      }

      const checkChildWindowState = setInterval(() => {
        if (authWindow?.closed) {
          clearInterval(checkChildWindowState);

          if (!receivedPostMessage) {
            resolve({
              ...response,
              ssoAbandoned: true,
            });
          }
        }
      }, 500);

      window.addEventListener("message", eventListener);
    });
  }

  async fetchTokens(
    authCode: string,
    codeVerifier: string,
    redirectUri: string
  ) {
    const url = new URL(this.tokenUrl, this.authBaseUrl);
    const method = "POST";
    const params = new URLSearchParams();
    params.append("grant_type", "authorization_code");
    params.append("code", authCode);
    params.append("code_verifier", codeVerifier);
    params.append("client_id", this.clientId);
    params.append("redirect_uri", redirectUri);
    params.append(
      "tapper_client_session_id",
      LaterpayBrowserClient.tapperClientSessionId
    );

    // tapper-endpoint: POST /oauth2/token
    const res = await fetch(url.toString(), {
      method,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body: params.toString(),
    });

    if (res.status === 200) {
      const result = transformSnakeObjToCamel(await res.json());
      this.accessToken = result.accessToken;
      this.refreshToken = result.refreshToken;
      return result;
    } else {
      const result = transformSnakeObjToCamel(await res.json());

      throw new Error(
        result.error?.message ??
          `Unexpected response status: ${res.status}\n\n${JSON.stringify(
            result,
            null,
            2
          )}`
      );
    }
  }

  async refreshTokens() {
    if (!this.refreshToken) {
      throw new Error("Cannot refresh tokens without a refresh token");
    }

    const url = new URL(this.tokenUrl, this.authBaseUrl);

    const method = "POST";
    const params = new URLSearchParams();
    params.append("grant_type", "refresh_token");
    params.append("refresh_token", this.refreshToken);
    params.append("client_id", this.clientId);
    params.append("scope", oauth2Scopes);
    params.append(
      "tapper_client_session_id",
      LaterpayBrowserClient.tapperClientSessionId
    );

    // tapper-endpoint: POST /oauth2/token
    const res = await fetch(url.toString(), {
      method,
      headers: {
        "content-type": "application/x-www-form-urlencoded",
      },
      body: params.toString(),
    });

    if (res.status === 200) {
      const result = transformSnakeObjToCamel(await res.json());
      this.accessToken = result.accessToken;
      this.refreshToken = result.refreshToken;
      return result;
    } else {
      const result = transformSnakeObjToCamel(await res.json());

      throw new Error(
        result.error?.message ??
          `Unexpected response status: ${res.status}\n\n${JSON.stringify(
            result,
            null,
            2
          )}`
      );
    }
  }

  async fetchTabs() {
    if (!this.accessToken) {
      throw new Error("Cannot fetch tabs without access token");
    }
    const url = new URL("/v1/tabs", this.tapiBaseUrl);
    const params = new URLSearchParams({
      payment_model: "pay_later",
      limit: "2",
    });

    // tapper-endpoint: GET /v1/tabs
    const res = await fetch(`${url}?${params}`, {
      headers: {
        authorization: `Bearer ${this.accessToken}`,
        accept: "application/json",
        "x-tapper-client-session-id":
          LaterpayBrowserClient.tapperClientSessionId,
      },
    });

    if (res.status === 200) {
      const snakeTabs: TabServerResponse = await res.json();
      const tabs: Tab[] = transformSnakeObjToCamel(snakeTabs.data);
      cowConsole.log("Received all tabs", tabs);

      const activeTab = tabs?.filter((tab) =>
        [TabStatus.Open, TabStatus.Full].includes(tab.status)
      )[0];

      const closedTab = tabs?.filter((tab) =>
        [TabStatus.Closed].includes(tab.status)
      )[0];

      return { activeTab, closedTab };
    } else {
      const result = transformSnakeObjToCamel(await res.json());

      throw new Error(
        result.error?.message ??
          `Unexpected response status: ${res.status}\n\n${JSON.stringify(
            result,
            null,
            2
          )}`
      );
    }
  }

  async fetchLatestTab() {
    if (!this.accessToken) {
      throw new Error("Cannot fetch tabs without access token");
    }
    const url = new URL("/v1/tabs", this.tapiBaseUrl);
    const params = new URLSearchParams({
      payment_model: "pay_later",
      limit: "1",
    });

    // tapper-endpoint: GET /v1/tabs
    const res = await fetch(`${url}?${params}`, {
      headers: {
        authorization: `Bearer ${this.accessToken}`,
        accept: "application/json",
        "x-tapper-client-session-id":
          LaterpayBrowserClient.tapperClientSessionId,
      },
    });

    if (res.status === 200) {
      const snakeTabs: TabServerResponse = await res.json();
      const tabs: Tab[] = transformSnakeObjToCamel(snakeTabs.data);
      cowConsole.log("Received latest tab", tabs);
      const openOrFullTab = tabs.filter((tab) =>
        [TabStatus.Open, TabStatus.Full].includes(tab.status)
      )[0];
      return openOrFullTab;
    } else {
      const result = transformSnakeObjToCamel(await res.json());

      throw new Error(
        result.error?.message ??
          `Unexpected response status: ${res.status}\n\n${JSON.stringify(
            result,
            null,
            2
          )}`
      );
    }
  }

  async fetchTab(tabId: string) {
    cowConsole.log("Fetching tab", { tabId }, "this: ", this);
    if (!this.accessToken) {
      throw new Error("Cannot fetch tab without access token");
    }
    const url = new URL(`/v1/tabs/${tabId}`, this.tapiBaseUrl);

    // tapper-endpoint: GET /v1/tabs/{tab_id}
    const res = await fetch(url.toString(), {
      headers: {
        authorization: `Bearer ${this.accessToken}`,
        accept: "application/json",
        "x-tapper-client-session-id":
          LaterpayBrowserClient.tapperClientSessionId,
      },
    });

    if (res.status === 200) {
      const snakeTab: Tab = await res.json();
      const tab: Tab = transformSnakeObjToCamel(snakeTab);
      cowConsole.log("Received tab", tab);
      return tab;
    } else {
      const result = transformSnakeObjToCamel(await res.json());

      throw new Error(
        result.error?.message ??
          `Unexpected response status: ${res.status}\n\n${JSON.stringify(
            result,
            null,
            2
          )}`
      );
    }
  }

  async purchaseItemOffering(
    itemOfferingId: string,
    currency: string
  ): Promise<{ detail: { purchaseOutcome: PurchaseOutcome }; tab: Tab }> {
    if (!this.accessToken) {
      throw new Error("Cannot purchase offering without access token");
    }

    const metadata = {
      title: document.title,
      url: document.URL,
    };

    // tapper-endpoint: POST /v1/purchase/{item_offering_id}
    const res = await fetch(
      new URL(
        `/v1/purchase/${itemOfferingId}?currency=${currency}`,
        this.tapiBaseUrl
      ).toString(),
      {
        method: "POST",
        headers: {
          authorization: `Bearer ${this.accessToken}`,
          accept: "application/json",
          "content-type": "application/json",
          "x-tapper-client-session-id":
            LaterpayBrowserClient.tapperClientSessionId,
        },
        body: JSON.stringify(
          transformCamelObjToSnake({
            metadata,
          })
        ),
      }
    );

    if (res.status === 201) {
      const result = transformSnakeObjToCamel(await res.json());
      cowConsole.log("Received add to tab response", result);
      return result;
    } else if (res.status === 402) {
      const result = transformSnakeObjToCamel(await res.json());
      cowConsole.log("Payment required", result);
      return result;
    } else {
      const result = transformSnakeObjToCamel(await res.json());

      throw new Error(
        result.error?.message ??
          `Unexpected response status: ${res.status}\n\n${JSON.stringify(
            result,
            null,
            2
          )}`
      );
    }
  }

  async checkAccess(contentKey: string): Promise<any> {
    if (!this.accessToken) {
      throw new Error("Cannot check access without access token");
    }
    const url = new URL("/v2/access/check", this.tapiBaseUrl);
    url.searchParams.set("content_key", contentKey);
    url.searchParams.set(
      "tapper_client_session_id",
      LaterpayBrowserClient.tapperClientSessionId
    );

    // tapper-endpoint: GET /v2/access/check
    const res = await fetch(url.toString(), {
      headers: {
        // Note the uppercase Bearer which this endpoint needs for some reason
        // (the others are fine with either lowercase or uppercase)
        authorization: `Bearer ${this.accessToken}`,
        accept: "application/json",
      },
    });

    if ([200, 404].includes(res.status)) {
      const result = await res.json();
      cowConsole.log("Received access response", result);
      return result;
    } else {
      const result = await res.json();

      throw new Error(
        result.error?.message ??
          `Unexpected response status: ${res.status}\n\n${JSON.stringify(
            result,
            null,
            2
          )}`
      );
    }
  }

  async fetchSubscription(subscriptionId: string) {
    const client = new PaymentApi(this.apiConfig);

    return await client.retrieveSubscriptionEndpointV1({
      subscriptionId,
    });
  }

  async startOffpagePayment(
    url: URL,
    tabId: string,
    testMode: boolean,
    checkoutWindow?: Window | null
  ): Promise<{ tab?: Tab; paymentCompleted?: false }> {
    url.searchParams.append("tab_id", tabId);
    url.searchParams.append("testmode", testMode ? "true" : "false");
    const childWindow = checkoutWindow ?? window.open("", "supertabCheckout");

    if (childWindow && childWindow.location) {
      childWindow.location.assign(url.toString());
    }

    return new Promise<{ tab: Tab }>((resolve, reject) => {
      const eventListener = (ev: MessageEvent) => {
        if (ev.source === childWindow && ev.origin === url.origin) {
          window.removeEventListener("message", eventListener as EventListener);
          childWindow?.close();
          cowConsole.log("Received message from checkout window", ev.data);
          if (ev.data.status === "payment_completed") {
            this.fetchTab(tabId).then((tab) => resolve({ tab }));
          } else if (ev.data.status === "payment_cancelled") {
            return { paymentCompleted: false };
          } else {
            reject(
              new Error(
                `Received unexpected data from checkout window: ${JSON.stringify(
                  ev.data
                )}`
              )
            );
          }
        }
      };
      window.addEventListener("message", eventListener);
    });
  }

  async fetchClientConfig(currency?: string) {
    const client = new ItemsApi(this.apiConfig);

    const clientConfig = await client.getClientConfigV1({
      currency: currency,
      clientId: this.clientId,
    });

    clientConfig.offerings.sort(
      (offeringA, offeringB) => offeringA.price.amount - offeringB.price.amount
    );

    return clientConfig;
  }

  async getCurrency(currencyIsoCode: string) {
    const client = new ItemsApi(this.apiConfig);

    return await client.getCurrencyV1({
      currencyIsoCode,
    });
  }

  async getUser(id: string): Promise<User> {
    cowConsole.log("Getting user", { id }, "this: ", this);
    if (!this.accessToken) {
      throw new Error("Cannot get user without access token");
    }
    const url = new URL(`/v1/identity/user/${id}`, this.tapiBaseUrl);

    // tapper-endpoint: GET /v1/identity/user/{user_id}
    const res = await fetch(url.toString(), {
      headers: {
        authorization: `Bearer ${this.accessToken}`,
        accept: "application/json",
        "x-tapper-client-session-id":
          LaterpayBrowserClient.tapperClientSessionId,
      },
    });

    if (res.status === 200) {
      const snakeUser: User = await res.json();
      const user: User = transformSnakeObjToCamel(snakeUser);
      cowConsole.log("Received user", user);
      return user;
    } else {
      const result = transformSnakeObjToCamel(await res.json());

      throw new Error(
        result.error?.message ??
          `Unexpected response status: ${res.status}\n\n${JSON.stringify(
            result,
            null,
            2
          )}`
      );
    }
  }
}
