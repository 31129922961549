import styled from "@emotion/styled";
import TabIndicator from "../../molecules/TabIndicator";

export const StyledTabIndicator = styled(TabIndicator)`
  width: 80px;
  height: 80px;
  margin-top: 4px;
  flex-shrink: 0;
  margin-bottom: 24px;
`;
