import React, { FC } from "react";
import { Trans, useTranslation } from "../../../util/i18n";
import Button from "../../atoms/Button";
import {
  ButtonContainer,
  ContentWrapper,
  CopyWrapper,
  TitleWrapper,
  WidgetHeader,
} from "../styled";

interface LoggedOutProps {
  onLogIn: () => void;
}

const LoggedOut: FC<LoggedOutProps> = ({ onLogIn }) => {
  const { t } = useTranslation();
  return (
    <>
      <WidgetHeader data-testid="lbw-header">
        <TitleWrapper data-testid="lbw-title">
          <Trans i18nKey="logOutTitle" />
        </TitleWrapper>
      </WidgetHeader>
      <ContentWrapper data-testid="lbw-content">
        <CopyWrapper>
          <CopyWrapper data-testid="logout-subtitle">
            <Trans i18nKey="logOutSubtitle" />
          </CopyWrapper>
          <CopyWrapper data-testid="logout-widget-copy">
            <Trans i18nKey="logOutWidgetCopy" />
          </CopyWrapper>
        </CopyWrapper>
        <ButtonContainer>
          <Button
            key="logBackIn"
            onClick={onLogIn}
            size="small"
            testId="login-button"
          >
            <Trans i18nKey="logInButton" />
          </Button>
        </ButtonContainer>
      </ContentWrapper>
    </>
  );
};

export default LoggedOut;
