import styled from "@emotion/styled";
import { AnimationPulse, FlexCenter } from "../../../styles/GlobalStyles";

export const Container = styled.div`
  ${FlexCenter}
  position: relative;
  justify-content: center;
  width: 80px;
  height: 80px;
  margin-top: 4px;
  flex-shrink: 0;
  &[data-actionable="true"] {
    cursor: pointer;
  }
  &[data-loading="true"] {
    ${AnimationPulse}
  }
  button[data-variant="icon"] {
    position: absolute;
    top: 8px;
    right: 0;
  }
`;

export const PriceContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  align-items: center;

  *:nth-child(2) {
    margin-bottom: -5px;
  }
`;
