import { createMachine } from "xstate";
import { ApiMachineContext, ApiMachineEvents } from "./types";
import * as actions from "./actions";
import * as services from "./services";
import * as guards from "./guards";

const ApiMachine = createMachine<ApiMachineContext, ApiMachineEvents>(
  {
    id: "api",
    initial: "initial",
    predictableActionArguments: true,
    states: {
      initial: {
        initial: "fetchingClientConfig",

        states: {
          fetchingClientConfig: {
            invoke: {
              id: "fetchClientConfig",
              src: "fetchClientConfig",
              onDone: {
                actions: ["assignClientConfig", "sendClientConfig"],
                target: "#api.idle",
              },
              onError: {
                actions: "sendError",
                target: "",
              },
            },
          },
        },
      },

      idle: {
        on: {
          LOGGED_IN: {
            actions: "assignTokens",
            target: "ready",
          },
        },
      },

      ready: {
        type: "parallel",
        on: {
          LOGGED_OUT: {
            actions: "removeTokens",
            target: "idle",
          },
          REFRESHED_TOKENS: {
            actions: "assignTokens",
          },
        },
        states: {
          gettingClientConfig: {
            initial: "idle",
            states: {
              idle: {
                on: {
                  FETCH_CLIENT_CONFIG: "pending",
                },
              },
              pending: {
                invoke: {
                  id: "fetchClientConfig",
                  src: "fetchClientConfig",
                  onDone: {
                    actions: "sendClientConfig",
                    target: "idle",
                  },
                  onError: {
                    actions: "sendError",
                    target: "idle",
                  },
                },
              },
            },
          },

          checkingAccess: {
            initial: "idle",
            states: {
              idle: {
                on: {
                  ACCESS_CHECK: "pending",
                },
              },
              pending: {
                invoke: {
                  id: "checkAccess",
                  src: "checkAccess",
                  onDone: {
                    actions: "sendAccessResult",
                    target: "idle",
                  },
                  onError: {
                    actions: "sendError",
                    target: "idle",
                  },
                },
              },
            },
          },

          fetchingTabs: {
            initial: "idle",
            states: {
              idle: {
                on: {
                  TAB_FETCH: "pending",
                },
              },
              pending: {
                invoke: {
                  id: "fetchTabs",
                  src: "fetchTabs",
                  onDone: {
                    actions: "sendTab",
                    target: "idle",
                  },
                  onError: {
                    actions: "sendError",
                    target: "idle",
                  },
                },
              },
            },
          },

          fetchingSubscription: {
            initial: "idle",
            states: {
              idle: {
                on: {
                  SUBSCRIPTION_FETCH: "pending",
                },
              },
              pending: {
                invoke: {
                  id: "fetchSubscription",
                  src: "fetchSubscription",
                  onDone: {
                    actions: "sendSubscription",
                    target: "idle",
                  },
                  onError: {
                    actions: "sendError",
                    target: "idle",
                  },
                },
              },
            },
          },

          addingToTab: {
            initial: "idle",
            states: {
              idle: {
                on: {
                  TAB_ADD: "pending",
                },
              },
              pending: {
                invoke: {
                  id: "addToTab",
                  src: "addToTab",
                  onDone: {
                    actions: "sendAddToTab",
                    target: "idle",
                  },
                  onError: {
                    actions: "sendError",
                    target: "idle",
                  },
                },
              },
            },
          },

          handlingPayment: {
            initial: "idle",
            states: {
              idle: {
                on: {
                  INIT_PAYMENT: "pending",
                },
              },
              pending: {
                invoke: {
                  id: "offpagePayment",
                  src: "offpagePayment",
                  onDone: [
                    {
                      actions: "sendTabPaid",
                      target: "idle",
                    },
                  ],
                  onError: {
                    actions: "sendError",
                    target: "idle",
                  },
                },
                on: {
                  INIT_PAYMENT: "pending",
                },
              },
            },
          },
        },
      },
    },
  },
  { services, guards, actions }
);

export default ApiMachine;
