import type { StandardLonghandProperties } from "csstype";

const countryByCurrency: Record<string, string> = {
  USD: "us",
  EUR: "eu",
  GBP: "gb",
  CAD: "ca",
  SGD: "sg",
  NZD: "nz",
  JPY: "jp",
  AUD: "au",
  BRL: "br",
  KRW: "kr",
  TRY: "tr",
  MXN: "mx",
  PLN: "pl",
  IDR: "id",
  ARS: "ar",
  INR: "in",
  SEK: "se",
  CHF: "ch",
};

const Flag = ({
  id: flagId,
  objectFit = "cover",
  size = "20px",
}: {
  id: string;
  objectFit?: StandardLonghandProperties["objectFit"];
  size?: string;
}) => {
  const id = countryByCurrency[flagId].toString();

  return (
    <img
      src={`https://flagcdn.com/h20/${id}.png`}
      srcSet={`https://flagcdn.com/h40/${id}.png 2x, https://flagcdn.com/h60/${id}.png 3x`}
      height={size}
      width={size}
      alt={id}
      style={{ objectFit, borderRadius: size, width: size, height: size }}
    />
  );
};

export default Flag;
