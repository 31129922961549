import React from "react";
import { BackawayProps, TabSummary } from "../../../types";
import Button from "../../atoms/Button";
import TabSheet, { Title, Body, Header } from "../../organisms/TabSheet";
import { BackawayOffer, BackawaySeparator, ButtonsContainer } from "./styled";
import { Trans, useTranslation } from "../../../util/i18n";
import DefaultTabCopyChildren from "../../molecules/DefaultTabCopyChildren";
import { SubTitle } from "../../organisms/BottomSheet";
import { Merchant } from "../../../../tapper-browser-client";
import { TitleStyled } from "../../organisms/BottomSheet/styled";

export enum OffpagePaymentState {
  AwaitingUserConfirmation = "awaiting_user_confirmation",
  AwaitingPaymentCompletion = "awaiting_payment_completion",
  Completed = "completed",
}

const TSOffpagePayment: React.FC<
  {
    className?: string;
    isSubscriptions: boolean;
    discount: number;
    onCancel: () => void;
    onConfirm: () => void;
    onShowInfo: () => void;
    onShowTabDetails: () => void;
    merchant: Pick<Merchant, "name">;
    state: OffpagePaymentState;
    backawayOffer?: BackawayProps;
    onBackawayOfferAccept: () => void;
  } & TabSummary
> = ({
  className,
  amount,
  currency,
  discount,
  limit,
  onCancel,
  onConfirm,
  onShowInfo,
  onShowTabDetails,
  isSubscriptions,
  merchant,
  state,
  backawayOffer,
  onBackawayOfferAccept,
}) => {
  const { t } = useTranslation();

  const TabCopy = (
    <DefaultTabCopyChildren
      onShowInfo={onShowInfo}
      onShowTabDetails={onShowTabDetails}
      amount={amount}
      limit={limit}
      currency={currency}
      isLoggedIn
    />
  );

  switch (state) {
    case OffpagePaymentState.AwaitingUserConfirmation:
    case OffpagePaymentState.AwaitingPaymentCompletion:
      return (
        <TabSheet
          currency={currency.isoCode}
          className={className}
          onBack={onCancel}
          title={
            <TitleStyled>
              <Trans
                i18nKey={
                  isSubscriptions ? "accessAllNow" : "youCompletedYourTab"
                }
                values={{ merchant }}
              />
            </TitleStyled>
          }
          subTitle={
            isSubscriptions ? (
              <SubTitle>
                <Trans
                  i18nKey="subscriptions.continueToPurchase"
                  values={{
                    discount,
                  }}
                />
              </SubTitle>
            ) : (
              <SubTitle>
                <Trans
                  i18nKey="payToContinue"
                  values={{
                    amount,
                    limit,
                    currency,
                  }}
                />
              </SubTitle>
            )
          }
        >
          <Body variant="compact" gap="30px">
            {!isSubscriptions && (
              <Header
                amount={amount}
                currency={currency}
                limit={limit}
                onIndicatorClick={onShowTabDetails}
                TabCopyChildren={TabCopy}
                {...(backawayOffer?.onAccept && {
                  TabActionsChildren: (
                    <Button onClick={onConfirm}>
                      <Trans i18nKey="payNow" />
                    </Button>
                  ),
                })}
              />
            )}
            <ButtonsContainer>
              {!backawayOffer?.onAccept ? (
                <Button onClick={onConfirm}>
                  <Trans
                    i18nKey={
                      isSubscriptions
                        ? "subscriptions.continueAndSave"
                        : "payNow"
                    }
                  />
                </Button>
              ) : (
                <BackawayOffer>
                  <BackawaySeparator>
                    <Trans i18nKey="backawaySeparator" />
                  </BackawaySeparator>
                  <Button variant="outlined" onClick={onBackawayOfferAccept}>
                    {backawayOffer?.buttonLabel ?? (
                      <Trans i18nKey="backawayButton" values={{ merchant }} />
                    )}
                  </Button>
                </BackawayOffer>
              )}
            </ButtonsContainer>
          </Body>
        </TabSheet>
      );
    case OffpagePaymentState.Completed:
      return (
        <TabSheet
          currency={currency.isoCode}
          className={className}
          title={
            <Title>
              <Trans i18nKey="thankYou" values={{ merchant }} />
            </Title>
          }
        >
          <Body variant="compact" gap="24px">
            {!isSubscriptions && (
              <Header
                amount={amount}
                currency={currency}
                limit={limit}
                showSuccessIndicator={false}
                TabCopyChildren={TabCopy}
              />
            )}
            <ButtonsContainer>
              <Button onClick={onCancel}>
                <Trans i18nKey="startReading" />
              </Button>
            </ButtonsContainer>
          </Body>
        </TabSheet>
      );
    default:
      return null;
  }
};

export default TSOffpagePayment;
