import { createMachine, actions as xactions } from "xstate";
import {
  AuthenticationMachineContext,
  AuthenticationMachineEvents,
} from "./types";

import * as services from "./services";
import * as actions from "./actions";
import * as guards from "./guards";
import * as delays from "./delays";
export * from "./types";

const { choose } = xactions;

const AuthenticationMachine = createMachine<
  AuthenticationMachineContext,
  AuthenticationMachineEvents
>(
  {
    id: "authentication",
    strict: true,
    preserveActionOrder: true,
    predictableActionArguments: true,
    schema: {
      events: {} as AuthenticationMachineEvents,
      context: {} as AuthenticationMachineContext,
    },
    context: {} as AuthenticationMachineContext,
    initial: "loggedOut",
    entry: "loadTokens",

    states: {
      loggedOut: {
        entry: choose([
          {
            cond: "isNotInitializing",
            actions: "removeTokens",
          },
        ]),
        on: {
          LOG_OUT: {
            actions: "sendLoggedOut",
          },
          START_AUTH: "authenticating",
          CHECK_AUTH: "checking",
        },
      },
      authenticating: {
        initial: "gettingAuthenticationCode",
        entry: "sendAuthEntryEvents",
        exit: "sendAuthExitEvents",
        on: {
          START_AUTH: "#authentication.authenticating",
        },

        states: {
          gettingAuthenticationCode: {
            invoke: {
              id: "authorizeClient",
              src: "authorizeClient",
              onDone: {
                actions: "saveTokens",
                target: "exchangingAccessToken",
              },
              onError: {
                target: "#authentication.loggedOut",
                actions: "sendAuthFailed",
              },
            },
            on: {
              CANCEL_AUTH: {
                actions: "closeAuthWindow",
              },
            },
          },

          exchangingAccessToken: {
            invoke: {
              id: "fetchTokens",
              src: "fetchTokens",
              onDone: {
                actions: "saveTokens",
                target: "identifyingUser",
              },
              onError: {
                target: "#authentication.loggedOut",
                actions: "sendAuthFailed",
              },
            },
          },

          identifyingUser: {
            invoke: {
              id: "identifyUser",
              src: "identifyUser",
              onDone: {
                actions: "saveTokens",
                target: "#authentication.loggedIn",
              },
              onError: {
                target: "#authentication.loggedOut",
                actions: ["sendAuthFailed", "trackError"],
              },
            },
          },
        },
      },
      checking: {
        initial: "checkingTokens",
        states: {
          checkingTokens: {
            always: [
              { target: "identifyingUser", cond: "hasTokens" },
              {
                target: "#authentication.loggedOut",
                actions: "sendAuthFailed",
              },
            ],
          },
          identifyingUser: {
            invoke: {
              id: "identifyUser",
              src: "identifyUser",
              onDone: {
                actions: "saveTokens",
                target: "#authentication.loggedIn",
              },
              onError: [
                {
                  target: "refreshingTokens",
                  cond: "isTokenExpired",
                },
                {
                  target: "#authentication.loggedOut",
                  actions: "sendAuthFailed",
                },
              ],
            },
          },

          refreshingTokens: {
            invoke: {
              id: "refreshTokens",
              src: "refreshTokens",
              onDone: {
                actions: "saveTokens",
                target: "identifyingUser",
              },
              onError: {
                target: "#authentication.loggedOut",
                actions: "sendAuthFailed",
              },
            },
          },
        },
      },
      loggedIn: {
        entry: "sendLoggedIn",
        initial: "idle",
        on: {
          LOG_OUT: { target: "loggedOut", actions: "sendLoggedOut" },
          START_AUTH: { actions: "sendLoggedIn" },
          CHECK_AUTH: { actions: "sendLoggedIn" },
        },
        states: {
          idle: {
            after: {
              BEFORE_ACCESS_TOKEN_EXPIRATION: {
                target: "refreshingTokens",
              },
            },
          },
          refreshingTokens: {
            invoke: {
              id: "refreshTokens",
              src: "refreshTokens",
              onDone: {
                actions: ["saveTokens", "sendRefreshedTokens"],
                target: "idle",
              },
              onError: {
                target: "#authentication.loggedOut",
                actions: "sendAuthFailed",
              },
            },
          },
        },
      },
    },
  },
  {
    services,
    actions,
    guards,
    delays,
  }
);

export default AuthenticationMachine;
