import React from "react";
import type { ItemOffering } from "../../../tapper-browser-client";
import { useTranslation } from "../../util/i18n";

export function OfferingBillingInterval({
  offering,
  loading,
}: {
  offering: ItemOffering;
  loading: boolean;
}) {
  const { t } = useTranslation();

  if (loading) {
    return <>"-"</>;
  }

  if (offering.timePassDetails?.validTimedelta) {
    return (
      <>
        {offering.recurringDetails ? "/ " : ""}
        {t("formattedTimeDelta", {
          value: offering.timePassDetails.validTimedelta,
        })}
      </>
    );
  }
  return null;
}
