import { css, SerializedStyles } from "@emotion/react";
import styled from "@emotion/styled";

const ColorGray = css`hsla(0, 0%, 90%, 1)`;
const ColorDarkGray = css`hsla(0, 0%, 0%, 1)`;
const ColorLightGray = css`hsla(198, 15%, 83%, 1)`;
const ColorTextLightGray = css`hsla(195, 8%, 62%, 1)`;
const ColorTextGray = css`hsla(234, 8%, 23%, 1)`;
const ColorLightBlack = css`hsla(0, 0%, 20%, 1)`;
const ColorPrimary = css`hsla(0, 0%, 0%, 1)`;
const ColorOffPrimary = css`hsla(0, 0%, 40%, 1)`;
const ColorDifferentGray = css`hsla(193, 8%, 62%, 1)`;
const ColorCreditCardGrey = css`hsla(0, 0%, 40%, 1)`;
const ColorWhite = css`hsla(0, 0%, 100%, 1)`;
const ColorBlack = css`hsl(0, 0%, 0%)`;
const ColorWarn = css`hsla(44, 100%, 82%, 1)`;
const ColorPurple = css`hsla(281, 53%, 33%, 1)`;
const Color8Gray = css`hsla(0, 0%, 53%)`;

interface ColorsObject {
  [key: string]: SerializedStyles;
}

export const Colors: ColorsObject = {
  primary: ColorPrimary,
  offprimary: ColorOffPrimary,
  textGray: ColorTextGray,
  textLightGray: ColorTextLightGray,
  gray: ColorGray,
  darkgray: ColorDarkGray,
  lightgray: ColorLightGray,
  lightblack: ColorLightBlack,
  differentgray: ColorDifferentGray,
  creditcardgrey: ColorCreditCardGrey,
  white: ColorWhite,
  black: ColorBlack,
  warn: ColorWarn,
  purple: ColorPurple,
  disabledGray: Color8Gray,
};

export const FlexCenter = css`
  display: flex;
  align-items: center;
`;

export const FlexCol = css`
  display: flex;
  flex-direction: column;
`;

export const FlexGrow = css`
  flex-grow: 1;
`;

export const FlexNoGrow = css`
  flex-grow: 0;
`;

export const TextSmall = css`
  font-size: 14px;
`;

export const TextTiny = css`
  font-size: 12px;
`;

export const TextMedium = css`
  font-size: 18px;
`;

export const OverflowHidden = css`
  overflow: hidden;
`;

export const AnimationPulse = css`
  animation: pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite;

  @keyframes pulse {
    0%,
    100% {
      opacity: 1;
    }
    50% {
      opacity: 0.5;
    }
  }
`;

export const ParagraphCss = css`
  color: ${Colors.offprimary};
  &[data-bold="true"] {
    font-weight: 700;
  }
`;

export const Paragraph = styled.p`
  ${ParagraphCss}
`;

export const ScreenSizes = {
  xs: "420px",
  sm: "640px",
  md: "768px",
  lg: "1024px",
  xl: "1280px",
  "2xl": "1536px",
};
