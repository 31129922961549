import React, { FC, ComponentProps } from "react";
import { CurrencyResponse } from "../../../../tapper-browser-client";
import { Container } from "./styled";
import { useTranslation } from "../../../util/i18n";
import { Price as BasePrice } from "@getsupertab/price-utils";

type PriceProps = ComponentProps<typeof Container> & {
  currency: CurrencyResponse;
  amount: number;
  weight?: "normal" | "medium" | "bold";
  showZeroCents?: boolean;
};

const Price: FC<PriceProps> = ({
  currency,
  amount,
  weight,
  colorWay = "primary",
  showZeroCents,
  ...props
}) => {
  const { i18n } = useTranslation();
  return (
    <Container {...props} data-weight={weight} colorWay={colorWay}>
      <BasePrice
        {...{
          amount,
          currency: currency.isoCode,
          baseUnit: currency.baseUnit,
          localeCode: i18n.language,
          showZeroFractionDigits: showZeroCents,
        }}
      />
    </Container>
  );
};

export default Price;
