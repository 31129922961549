import styled from "@emotion/styled";
import { Colors, FlexCenter, TextSmall } from "../../../styles/GlobalStyles";
import { EmotionTheme } from "../../../styles/types";

export const ButtonStyled = styled.button<{ theme: EmotionTheme }>`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid ${({ theme }) => theme.colors?.background ?? Colors.primary};
  border-radius: 100px;
  padding: 12px 16px;
  font-weight: 700;
  background: ${({ theme }) => theme.colors?.background ?? Colors.primary};
  color: ${({ theme }) => theme.colors?.text ?? Colors.white};
  cursor: pointer;
  &:active,
  &:focus {
    * {
      color: ${({ theme }) => theme.colors?.text ?? Colors.white};
    }
    color: ${({ theme }) => theme.colors?.text ?? Colors.white};
  }
  &:focus-visible {
    outline: 2px solid inherit;
    outline-offset: 2px;
  }
  &[disabled] {
    cursor: not-allowed;
    background: ${({ theme }) => theme.colors?.background ?? Colors.primary};
    border-color: ${({ theme }) => theme.colors?.background ?? Colors.primary};
  }
  &[data-variant="outlined"] {
    display: inline;
    font-weight: 400;
    border-radius: 6px;
    border-color: ${({ theme }) => theme.colors?.background ?? Colors.gray};
    background: ${({ theme }) => theme.colors?.text ?? Colors.white};
    color: ${({ theme }) => theme.colors?.background ?? Colors.primary};
    &:active,
    &:focus {
      background: ${({ theme }) => theme.colors?.text ?? Colors.white};
      color: ${({ theme }) => theme.colors?.text ?? Colors.white};
      * {
        color: ${({ theme }) => theme.colors?.background ?? Colors.primary};
      }
    }
  }
  &[data-variant="secondary"] {
    display: block;
    border-color: ${Colors.lightgray};
    background: ${Colors.lightgray};
    color: ${Colors.primary};
  }
  &[data-variant="icon"] {
    ${FlexCenter}
    ${TextSmall}
    padding: 0;
    justify-content: center;
    width: 20px;
    height: 20px;
    line-height: 12px;
  }
  &[data-variant="inline-icon"] {
    ${FlexCenter}
    background: ${Colors.differentgray};
    border-color: transparent;
    display: inline;
    font-size: 10px;
    height: 16px;
    justify-content: center;
    letter-spacing: 0em;
    line-height: 12px;
    padding: 0;
    width: 16px;
  }
  &[data-variant="inline-outlined-icon"] {
    ${FlexCenter}
    background: ${Colors.white};
    border-color: 1px solid ${Colors.lightblack};
    color: ${Colors.lightblack};
    border-radius: 50%;
    justify-content: center;
    align-self: center;
    width: 13px;
    height: 13px;
    font-size: 8px;
    font-weight: 700;
    padding: 0;
  }
  &[data-variant="text"],
  &[data-variant="quiet"],
  &[data-variant="underline"] {
    border-radius: 0;
    padding: 0;
    border-color: transparent;
    border-width: 0;
    background: transparent;
    color: ${Colors.primary};
  }
  &[data-variant="quiet"] {
    letter-spacing: 0.02em;
    color: ${Colors.lightblack};
    ${TextSmall}
    font-weight: 400;
    text-decoration: underline;
  }
  &[data-variant="underline"] {
    text-decoration: underline;
  }
  &[data-weight="normal"] {
    font-weight: 400;
  }
  &[data-weight="bold"] {
    font-weight: 700;
  }
  &[data-size="small"] {
    ${TextSmall}
    height: 32px;
    padding: 8px 16px;
    line-height: 14px;
  }
`;
