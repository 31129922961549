import React, { FC } from "react";
import useRemainingTime from "../../../hooks/useRemainingTime";
import {
  Label,
  Progress,
  ProgressBar,
  Time,
  TimeSection,
  TimeWrapper,
} from "./styled";

interface RemainingTimeProps {
  validTo?: Date;
  accessTime: number;
  onTimeEnd?: () => void;
}

const RemainingTime: FC<RemainingTimeProps> = ({
  validTo,
  accessTime,
  onTimeEnd,
}) => {
  const { remainingTime, timePercentage } = useRemainingTime(
    validTo,
    accessTime
  );

  if (validTo && new Date(validTo).getTime() <= new Date().getTime()) {
    onTimeEnd?.();
  }

  return (
    <>
      <TimeWrapper data-testid="remaining-time">
        {remainingTime.map((item) => (
          <TimeSection
            key={item.label}
            data-testid={`lbw-remaining-time-${item.label}`}
          >
            <Time>{item.time}</Time>
            <Label>{item.label}</Label>
          </TimeSection>
        ))}
      </TimeWrapper>
      <ProgressBar data-testid="progress-bar">
        <Progress progress={timePercentage} />
      </ProgressBar>
    </>
  );
};

export default RemainingTime;
