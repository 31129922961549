import createCache from "@emotion/cache";
import { CacheProvider, ThemeProvider } from "@emotion/react";
import { inspect } from "@xstate/inspect";
import React from "react";
import ReactDOM from "react-dom";
import { ActionTypes, Event, interpret } from "xstate";
import { environment } from "./environment";
import { cowConsole } from "./util/cowConsole";
import { trackTransition } from "./util/gtm";
import OnpageWidget from "./widget/components/widgets/OnpageWidget";
import onpageWidgetMachine from "./widget/statecharts/onpageWidget";
import {
  BackToCallerEventDetail,
  OnpageWidgetEvent,
  OnpageWidgetService,
  UserAccessStatus,
} from "./widget/statecharts/onpageWidget/types";
import { BackawayProps, CowCustomizations, UiConfig } from "./widget/types";
import { CurrencyResponse } from "@getsupertab/tapper-sdk";
import { EmotionTheme } from "./widget/styles/types";

export default class CtoOnpageWidget {
  clientId: string;
  merchantName: string;
  tapiBaseUrl: string;
  authBaseUrl: string;
  ssoBaseUrl: string;
  checkoutBaseUrl: string;
  userPortalBaseUrl: string;
  accessStatus?: UserAccessStatus;
  backawayOffer?: BackawayProps;
  customization?: CowCustomizations;
  localeCode?: string;
  authUrl?: string;
  tokenUrl?: string;
  uiConfig?: UiConfig;

  private merchantSlug: string;
  private cleanUpRender?: () => void;
  private service: OnpageWidgetService;

  constructor({
    clientId,
    merchantName,
    tapiBaseUrl = environment.tapiBaseUrl,
    authBaseUrl = environment.authBaseUrl,
    ssoBaseUrl = environment.ssoBaseUrl,
    checkoutBaseUrl = environment.checkoutBaseUrl,
    userPortalBaseUrl = environment.userPortalBaseUrl,
    backawayOffer,
    customization,
    skipInitialAccessCheck,
    localeCode,
    autoStart = true,
    authUrl = environment.authUrl,
    tokenUrl = environment.tokenUrl,
    uiConfig,
  }: {
    clientId: string;
    merchantName: string;
    tapiBaseUrl?: string;
    authBaseUrl?: string;
    ssoBaseUrl?: string;
    checkoutBaseUrl?: string;
    userPortalBaseUrl?: string;
    backawayOffer?: BackawayProps;
    customization?: CowCustomizations;
    skipInitialAccessCheck?: boolean;
    localeCode?: string;
    autoStart?: boolean;
    authUrl?: string;
    tokenUrl?: string;
    uiConfig?: UiConfig;
  }) {
    this.clientId = clientId;
    this.merchantName = merchantName;
    this.merchantSlug = this.slug(merchantName);
    this.tapiBaseUrl = tapiBaseUrl;
    this.authBaseUrl = authBaseUrl;
    this.ssoBaseUrl = ssoBaseUrl;
    this.checkoutBaseUrl = checkoutBaseUrl;
    this.userPortalBaseUrl = userPortalBaseUrl;
    this.backawayOffer = backawayOffer;
    this.customization = customization;
    this.localeCode = localeCode;
    this.uiConfig = uiConfig;
    this.service = this.createOnpageWidgetMachine({ skipInitialAccessCheck });
    this.authUrl = authUrl;
    this.tokenUrl = tokenUrl;
    this.service = this.createOnpageWidgetMachine({ skipInitialAccessCheck });

    this.service.onEvent((evt) => {
      if (evt.type === "LOGGED_OUT") {
        window.location.reload();
      }

      if (evt.type === ActionTypes.Init) {
        this.cleanUpRender = this.render();
      }
    });

    if (autoStart) {
      this.start();
    }
  }

  start() {
    this.service.start();
  }

  destroy(): boolean {
    const isUserLoggedIn = this.service.getSnapshot().context.userAuthorized;
    if (isUserLoggedIn) {
      cowConsole.warn(
        "Ignoring destroy call because user is logged in and we want to preserve the LBW."
      );
      return false;
    }
    this.service.stop();
    this.cleanUpRender?.();
    return true;
  }

  async waitForNextBackToCallerEvent(): Promise<BackToCallerEventDetail> {
    return new Promise<BackToCallerEventDetail>((res) => {
      const eventHandler = (
        event: CustomEventInit<BackToCallerEventDetail>
      ) => {
        if (event.detail?.type === "back_to_caller") {
          res(event.detail);
          window.removeEventListener(
            environment.widgetCustomEventName,
            eventHandler
          );
        }
      };
      window.addEventListener(environment.widgetCustomEventName, eventHandler);
    });
  }

  // Starts an access check and returns a promise that is resolved with the
  //  `detail` property of the `back_to_caller` event.
  async checkAccess(): Promise<BackToCallerEventDetail> {
    const detail = this.waitForNextBackToCallerEvent();
    // If user is logged in, perform access check
    this.service.send("CHECK_ACCESS");
    return await detail;
  }

  //@Deprecated // Use showPaywall instead
  contribute() {
    this.showPaywall();
  }

  showPaywall() {
    this.service.send("CONTRIBUTE");
  }

  logIn() {
    this.service.send({ type: "LOG_IN", loginAction: "login_widget_call" });
  }

  logOut() {
    this.service.send("LOG_OUT");
  }

  send(evt: Event<OnpageWidgetEvent>) {
    this.service.send(evt);
  }

  private createOnpageWidgetMachine({
    skipInitialAccessCheck,
  }: {
    skipInitialAccessCheck?: boolean;
  }) {
    const devTools = window.location.search.includes("xstate-inspect");
    const verbose = window.location.search.includes("xstate-verbose");
    const checkoutType =
      false === (window as any).__SUPERTAB_DEBUG__?.showOfferings
        ? "simplified"
        : "normal";

    const service = interpret(
      onpageWidgetMachine.withContext({
        currency: { isoCode: "USD", baseUnit: 100 } as CurrencyResponse,
        defaultTabLimit: 500,
        userAccessStatus: UserAccessStatus.UNKNOWN,
        userAccessDetails: [],
        isSubscriptionEnabled: false,
        discount: 20,
        merchant: {
          clientId: this.clientId,
          name: this.merchantName,
        },
        userAuthorized: false,
        api: {
          tapiBaseUrl: this.tapiBaseUrl,
          authBaseUrl: this.authBaseUrl,
          authUrl: this.authUrl,
          tokenUrl: this.tokenUrl,
          ssoBaseUrl: this.ssoBaseUrl,
          checkoutBaseUrl: this.checkoutBaseUrl,
          userPortalBaseUrl: this.userPortalBaseUrl,
        },
        gtmContainerId: environment.gtmContainerId,
        gtmDataLayerName: "COW",
        backawayOffer: this.backawayOffer,
        customization: this.customization,
        skipInitialAccessCheck,
        localeCode: this.localeCode ?? window?.navigator.language,
        checkoutType,
      }),
      { devTools }
    );

    if (devTools) {
      this.applyInstrumentation(service);
    }

    if (verbose) {
      service.onTransition((state, evt) => {
        cowConsole.log("LOG", evt.type, state.toStrings());
      });
    }

    if (environment.gtmContainerId) {
      this.applyAnalytics(service);
    }

    return service;
  }

  private applyInstrumentation(service: OnpageWidgetService) {
    const start = service.start.bind(service);

    service.start = (...args) => {
      inspect({ iframe: false });
      setTimeout(() => start(...args), 300);
      return service;
    };
  }

  private applyAnalytics(service: OnpageWidgetService) {
    service.onTransition(trackTransition);

    return service;
  }

  private slug(input: string): string {
    return input.toLowerCase().replace(/\s/g, "-");
  }

  private render(): () => void {
    const containerElement = document.createElement("div");
    containerElement.setAttribute("data-testid", "widget-container");
    containerElement.setAttribute("id", "cto-onpage-widget-container");
    containerElement.setAttribute(
      "style",
      "position:sticky; z-index:2147483647"
    );

    const shadowRoot = containerElement.attachShadow({ mode: "open" });
    const appElement = document.createElement("div");

    containerElement.appendChild(shadowRoot);
    shadowRoot.appendChild(appElement);

    document.body.appendChild(containerElement);

    const emotionCache = createCache({
      key: "cow-css",
      container: shadowRoot,
      speedy: true,
    });

    const interval = setInterval(() => {
      if (containerElement.nextElementSibling) {
        const stylesheets = shadowRoot.styleSheets;
        const styleRules = Array.from(stylesheets).map((stylesheet) =>
          Array.from(stylesheet.cssRules).map((rule) => rule.cssText)
        );

        cowConsole.log(
          "Re-mounting container element because it isn't the last sibling"
        );
        document.body.appendChild(containerElement);

        cowConsole.log("Re-attaching shadow root styles.");
        for (let i = 0; i < styleRules.length; i++) {
          styleRules[i]
            .reverse()
            .map((style) => stylesheets[i].insertRule(style));
        }
      }
    }, 1000);

    const Component = () => {
      const emotionTheme: EmotionTheme = {
        colors: this.uiConfig?.colors,
      };

      return (
        <React.StrictMode>
          <CacheProvider value={emotionCache}>
            <ThemeProvider theme={emotionTheme}>
              <OnpageWidget service={this.service} />
            </ThemeProvider>
          </CacheProvider>
        </React.StrictMode>
      );
    };

    /* eslint-disable */
    ReactDOM.render(<Component />, appElement);

    // Return clean-up function
    return () => {
      clearInterval(interval);
      ReactDOM.unmountComponentAtNode(appElement);
      document.body.removeChild(containerElement);
    };
    /* eslint-enable */
  }
}
