import { ApiMachineContext, ApiMachineEvents } from "./types";

export function hasCancelledPayment(
  _: ApiMachineContext,
  ev: ApiMachineEvents
) {
  if (ev.type !== "done.invoke.offpagePayment") {
    throw new Error(`${ev.type} is not supported by this guard.`);
  }
  return ev.data.paymentCompleted === false;
}
