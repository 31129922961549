import { ComponentProps } from "react";
import { Text } from "./styled";
import { useTranslation } from "react-i18next";

export const CurrencySymbol = ({
  isoCode,
  onlyWhenSameAsIsoCode = false,
}: {
  isoCode: string;
  onlyWhenSameAsIsoCode?: boolean;
} & ComponentProps<"span">) => {
  const { i18n } = useTranslation();

  return (
    <Text
      localeCode={i18n.language}
      currency={isoCode}
      onlyWhenSameAsIsoCode={onlyWhenSameAsIsoCode}
    />
  );
};

export default CurrencySymbol;
