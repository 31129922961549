import { config as springPresets } from "react-spring";

export const springConfig = {
  ...springPresets.stiff,
  clamp: true,
};

type Unit = "second" | "minute" | "hour" | "day" | "week" | "month" | "year";

export function parseTimeDelta(delta: string): {
  count: number;
  unit: Unit;
  formattedValidTime: string;
} {
  const regex = /^(\d*)([s|m|h|d|w|M|y])$/;
  const match = regex.exec(delta);
  if (!match) {
    throw new Error(`${delta} is not a valid time delta`);
  }
  const [, quantifier, qualifier] = match;
  const count = parseInt(quantifier);
  const unit = {
    s: "second",
    m: "minute",
    h: "hour",
    d: "day",
    w: "week",
    M: "month",
    y: "year",
  }[qualifier] as Unit;
  const formattedValidTime = `${count} ${unit}`;

  return {
    count,
    unit,
    formattedValidTime,
  };
}

export const isMobile = {
  Android: function () {
    return navigator.userAgent.match(/Android/i);
  },
  BlackBerry: function () {
    return navigator.userAgent.match(/BlackBerry/i);
  },
  iOS: function () {
    return navigator.userAgent.match(/iPhone|iPad|iPod/i);
  },
  Opera: function () {
    return navigator.userAgent.match(/Opera Mini/i);
  },
  Windows: function () {
    return (
      navigator.userAgent.match(/IEMobile/i) ||
      navigator.userAgent.match(/WPDesktop/i)
    );
  },
  any: function () {
    return (
      isMobile.Android() ||
      isMobile.BlackBerry() ||
      isMobile.iOS() ||
      isMobile.Opera() ||
      isMobile.Windows()
    );
  },
};

export const timestampInMilliseconds = (timestamp: number) => {
  return timestamp * 1000;
};
