import { PurchaseOutcome } from "../../../tapper-browser-client";
import { THANK_YOU_MESSAGE_DURATION } from "../leavebehindWidget/delays";
import { OnpageWidgetContext } from "./types";

export const BACK_TO_CALLER_DELAY = (ctx: OnpageWidgetContext) =>
  !!ctx.leaveBehindMachineRef &&
  (ctx.purchaseOutcome === PurchaseOutcome.Added || !!ctx.tabPaid)
    ? THANK_YOU_MESSAGE_DURATION
    : 0;

export const SIMPLIFIED_FLOW_INITIATE_PAYMENT_DELAY = 2000;
