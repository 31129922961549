import styled from "@emotion/styled";
import {
  Colors,
  FlexCol,
  ParagraphCss,
  TextSmall,
  TextTiny,
} from "../../../styles/GlobalStyles";
import Button from "../../atoms/Button";
import TabIndicator from "../../molecules/TabIndicator/index";
import { ReactComponent as Info } from "../../../../svg/info.svg";

export const TimeDeltaInfo = styled.span`
  font-weight: bold;
  white-space: nowrap;
`;

export const ActionsContainer = styled.div`
  ${FlexCol};
  gap: 10px;
  text-align: center;

  button[data-variant="secondary"] {
    margin-top: 12px;
    width: 100%;
  }
`;

// TODO: Delete big margin prop once subscriptions is live
export const AlreadyHaveATab = styled.span`
  ${TextSmall}
  margin: 8px auto 0;
  line-height: 20px;
  letter-spacing: -0.01em;
  text-align: left;
  color: hsla(234, 8%, 23%, 1);
  button {
    font-size: inherit;
  }
`;

export const InfoParagraph = styled.p`
  ${ParagraphCss}
  color: ${Colors.primary};
  text-align: center;
  padding: 8px;

  &[data-variant="warn"] {
    background-color: ${Colors.warn};
    border-radius: 2px;
  }
`;

export const OfferingsWrapper = styled.div`
  display: flex;
  gap: 10px;
  flex-direction: column;
`;

export const OfferingsGroup = styled.div`
  width: 100%;
  gap: 24px;
  display: flex;
  flex-direction: column;
`;

export const CancelButton = styled(Button)`
  width: 100%;
  margin-top: 12px;
`;

export const TabCopyChildrenOfferingChip = styled.div`
  border: 1px solid #d9dee0;
  border-radius: 100px;
  padding: 8px 12px;
  width: fit-content;
`;

export const TabInfoLimit = styled.span<{ leftAlignment?: "left" | "center" }>`
  ${TextSmall}
  color: hsla(234, 8%, 23%, 1);
  display: grid;
  grid-template-columns: 14px auto;
  justify-content: ${({ leftAlignment }) =>
    leftAlignment === "center" ? leftAlignment : "start-align"};
  gap: 6px;
  text-align: ${({ leftAlignment }) => leftAlignment};
  align-items: start-align;
  font-weight: 300;
  line-height: 19px;
  letter-spacing: 0.02em;
  padding: 0 18px;
`;

export const InfoButton = styled.div`
  width: 14px;
  height: 14px;
  margin-top: 2px;

  :hover {
    cursor: pointer;
  }
`;

export const InfoIcon = styled(Info)`
  width: 20px;
  height: 14px;
`;

export const TabInfoLimitPlaceholder = styled.span`
  display: block;
  height: 19px;
`;

export const TabCopyChildrenContainerSecondP = styled.p`
  color: ${Colors.offprimary};
  ${TextTiny}
  font-weight: 300;
  line-height: 18px;
`;

export const TabCopyChildrenContainerFirstP = styled.p`
  line-height: 20px;
`;

export const TabCopyChildrenContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

export const SectionsWrapper = styled.div`
  margin: auto;
  text-align: center;
`;

export const TabIndicatorWrapper = styled(TabIndicator)`
  margin: 0 auto 24px;
`;

export const SubmitButton = styled(Button)`
  width: 100%;
  margin-bottom: 18px;
`;

export const AccessGainedContainer = styled.div`
  color: ${Colors.primary};
  strong {
    white-space: nowrap;
  }
`;
