import Button from "../../atoms/Button";
import { Trans, useTranslation } from "../../../util/i18n";
import {
  ContactMessage,
  ErrorBody,
  ErrorContent,
  ErrorParagraph,
  ErrorTitle,
} from "./styled";
import TabSheet from "../../organisms/TabSheet";
import { environment } from "../../../../environment";

const TSError = ({
  onBack,
  error,
  testMode,
  currency,
}: {
  onBack: () => void;
  error: any;
  testMode?: boolean;
  currency: string;
}) => {
  const { t } = useTranslation();
  const errorMessage = error?.message;

  return (
    <TabSheet
      currency={currency}
      testMode={testMode}
      title={
        <ErrorTitle>
          <Trans i18nKey="errorTitle" />
        </ErrorTitle>
      }
    >
      <ErrorBody>
        <ErrorParagraph>
          {errorMessage ? errorMessage : <Trans i18nKey="errorParagraph" />}
        </ErrorParagraph>
        <ErrorContent>
          <Button onClick={onBack}>
            <Trans i18nKey="back" />
          </Button>
          <ContactMessage>
            <Trans
              i18nKey="contactSupport"
              components={[
                // eslint-disable-next-line jsx-a11y/anchor-has-content
                <a
                  href={environment.customerSupportUrl}
                  key="customerSupport"
                />,
              ]}
            />
          </ContactMessage>
        </ErrorContent>
      </ErrorBody>
    </TabSheet>
  );
};

export default TSError;
