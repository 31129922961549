import styled from "@emotion/styled";
import { Body } from "../../organisms/BottomSheet";
import { FlexCol } from "../../../styles/GlobalStyles";
export const InfoBody = styled(Body)`
  ${FlexCol}
  gap: 8px;
  margin: 16px;
  text-align: center;

  strong {
    font-weight: 500;
  }

  button {
    margin: 16px 0;
  }
`;
