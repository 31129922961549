import styled from "@emotion/styled";
import {
  AnimationPulse,
  Colors,
  FlexCol,
  TextSmall,
  TextTiny,
} from "../../styles/GlobalStyles";
import Button from "../atoms/Button";
import { EmotionTheme } from "../../styles/types";

export const OfferingsContainer = styled.fieldset`
  display: flex;
  gap: 6px;
  min-height: 52px;
  margin: 0;
  padding: 0;
  overflow-x: auto;
  transition: all;
  margin: 0 18px;

  &:focus-visible {
    outline: 2px solid inherit;
  }

  button[data-variant="outlined"] {
    width: 100%;
    ${FlexCol}
    align-items: center;
  }

  &[data-loading="true"] {
    ${AnimationPulse}

    button[data-variant="outlined"] {
      opacity: 0.5;
      cursor: wait;
    }
  }
`;

export const OfferingTimeDelta = styled.div<{ theme: EmotionTheme }>`
  margin-top: -3px;
  ${TextTiny}
  line-height: 15px;
  color: ${Colors.lightblack};

  &[data-checked="true"] {
    color: ${({ theme }) => theme.colors?.text ?? Colors.white};
  }
`;

export const CancelButton = styled(Button)`
  width: 100%;
  margin-top: 12px;
`;

export const OfferingContainer = styled.label`
  align-items: center;
  cursor: pointer;
  display: flex;
  flex: 1;
  ${TextSmall}
  font-weight: 400;
  justify-content: center;
  height: 52px;
  pointer-events: auto;
  max-width: 116px;
  margin: 0 auto;
`;

export const OfferingText = styled.div<{ theme: EmotionTheme }>`
  width: 100%;
  letter-spacing: 0.01em;
  padding: 8px 16px;
  border-radius: 100px;
  border: 1px solid ${Colors.lightgray};
  white-space: nowrap;
  color: ${Colors.primary};
  background-color: ${Colors.white};
  text-align: center;
  > span {
    color: inherit;
  }
  input:checked + & {
    color: ${({ theme }) => theme.colors?.text ?? Colors.white};
    background-color: ${({ theme }) =>
      theme.colors?.background ?? Colors.primary};
    border-color: ${Colors.lightgray};
  }

  input:focus-visible + & {
    outline: 4px solid;
    outline-color: activeborder;
    outline-color: -webkit-focus-ring-color;
    outline-offset: -7px;
  }
`;

export const OfferingInput = styled.input`
  &[type="radio"] {
    position: absolute;
    opacity: 0;
  }
`;

export const SingleOfferingContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 6px;
  max-width: 280px;
  margin: 0 auto;
  align-items: center;
  text-align: center;
`;

export const SingleOffering = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: ${Colors.lightblack};
`;

export const TextAndButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 12px;
`;

export const SubscriptionToggleWrapper = styled.div`
  ${TextSmall}
  color: ${Colors.primary};
  align-items: top;
  display: flex;
  flex-direction: row;
  gap: 6px;
  justify-content: left;
  align-items: baseline;
  margin: 0 18px;

  & button {
    top: 1px;
  }
`;

export const TimePassCopyWrapper = styled.div`
  ${TextSmall}
  color: ${Colors.primary};
  gap: 6px;
  margin: 0 auto;
  margin-bottom: 12px;
`;

export const ToggleWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 50%;
  transform: translateY(-50%);
`;
