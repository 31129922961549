import styled from "@emotion/styled";
import { Colors, TextMedium } from "../../../styles/GlobalStyles";

export const StoryWrapper = styled.div`
  width: 105px;
  justify-content: center;
  display: flex;
  flex-direction: column;
`;

export const TimeWrapper = styled.div`
  color: ${Colors.primary};
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin: 8px auto 0;
`;

export const TimeSection = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

export const Time = styled.span`
  font-size: 32px;
  line-height: 30px;
  font-weight: 500;
`;

export const Label = styled.span`
  ${TextMedium}
  font-weight: 500;
`;

export const ProgressBar = styled.div`
  width: 90px;
  height: 10px;
  display: block;
  background: ${Colors.gray};
  border-radius: 10px;
  position: relative;
  margin: auto;
`;

export const Progress = styled.span<{ progress: number }>`
  position: absolute;
  width: ${({ progress }) => (progress ? `${progress}%` : 0)};
  height: 10px;
  display: block;
  border-radius: 10px;
  top: 0;
  left: 0;
  background: black;
`;
