import CtoOnpageWidget from "./cto-onpage-widget";
import { cowConsole } from "./util/cowConsole";
import {
  BackToCallerEventDetail,
  UserAccessStatus,
} from "./widget/statecharts/onpageWidget";
import {
  DestroyParams,
  InitializeParams,
  InitializeResponse,
  MonetizationProvider,
  MonetizeParams,
  MonetizeResponse,
  Money,
  UserEntitlementStateEnumDefaultType,
} from "./supertab-monetization-provider.types";

/**
 * COW wrapper class
 *
 * Internally, it talks to `CtoOnpageWidget`.
 * Externally, it exposes a promise API.
 */
class SupertabMonetizationProvider implements MonetizationProvider {
  widget?: CtoOnpageWidget;
  clientId?: string;
  merchantName?: string;

  constructor({
    clientId,
    merchantName,
  }: {
    // Client ID and Merchant Name are optional here, because they can be
    // passed in `additionalInitializeParams` of `initialize` method.
    // - Google passes them in `additionalInitializeParams` of `initialize` method.
    // - Custom integrations can pass them here in the constructor.
    clientId?: string;
    merchantName?: string;
  } = {}) {
    cowConsole.log("SupertabMonetizationProvider: constructor");
    this.clientId = clientId;
    this.merchantName = merchantName;
  }

  private createInitializeResponse({
    initializeParams,
    initializeSuccess,
  }: {
    initializeParams: InitializeParams;
    initializeSuccess: boolean;
  }) {
    return {
      apiVersionInUse: "1.0.0",
      initializeSuccess,
      signInMonetizationPortalSupported: true,
    };
  }

  async initialize(
    initializeParams: InitializeParams
  ): Promise<InitializeResponse> {
    cowConsole.log("SupertabMonetizationProvider: initialize", {
      initializeParams,
    });

    const initializeResponseFailed = this.createInitializeResponse({
      initializeParams,
      initializeSuccess: false,
    });

    // Extract `merchantName` and `clientId` from `additionalInitializeParams`
    // or use the values passed in the constructor.
    const { merchantName = this.merchantName, clientId = this.clientId } =
      initializeParams.additionalInitializeParams ?? {};

    // Assert `merchantName`
    if (!merchantName) {
      console.error(
        "SupertabMonetizationProvider: initialize: Missing `merchantName`. Either pass it in the constructor or in `additionalInitializeParams`."
      );
      return initializeResponseFailed;
    }

    // Assert `clientId`
    if (!clientId) {
      console.error(
        "SupertabMonetizationProvider: initialize: Missing `clientId`. Either pass it in the constructor or in `additionalInitializeParams`."
      );
      return initializeResponseFailed;
    }

    // Try to initialize widget and wait for it to become ready
    try {
      this.widget = new CtoOnpageWidget({
        merchantName,
        clientId,
        skipInitialAccessCheck: true,
        ...(initializeParams.suggestedLanguageCode && {
          localeCode: initializeParams.suggestedLanguageCode,
        }),
      });
      // The widget should be ready once we receive the first back_to_caller event
      // TODO: Detect errors
      await this.widget.waitForNextBackToCallerEvent();
      return this.createInitializeResponse({
        initializeParams,
        initializeSuccess: true,
      });
    } catch (e) {
      console.error("SupertabMonetizationProvider: Failed to initialize.", e);
      return this.createInitializeResponse({
        initializeParams,
        initializeSuccess: false,
      });
    }
  }

  async getUserEntitlementState(): Promise<UserEntitlementStateEnumDefaultType> {
    cowConsole.log("SupertabMonetizationProvider: getUserEntitlementState");

    const widget = this.assertWidget();
    const detail = await widget.checkAccess();
    return this.userAccessStatusToUserEntitlementState(detail.accessStatus);
  }

  async monetize(monetizeParams: MonetizeParams): Promise<MonetizeResponse> {
    cowConsole.log("SupertabMonetizationProvider: monetize", {
      monetizeParams,
    });

    const widget = this.assertWidget();

    switch (monetizeParams.monetizationPortal) {
      case window.googlefc.monetization.MonetizationPortalEnum.PORTAL_SIGN_IN: {
        widget.logIn();
        const detail = await widget.waitForNextBackToCallerEvent();
        const response = this.backToCallerEventDetailToMonetizeResponse(detail);
        cowConsole.log("SupertabMonetizationProvider: monetize", { response });
        return response;
      }
      case window.googlefc.monetization.MonetizationPortalEnum
        .PORTAL_PRIMARY_ACCESS: {
        widget.showPaywall();
        const detail = await widget.waitForNextBackToCallerEvent();
        const response = this.backToCallerEventDetailToMonetizeResponse(detail);
        cowConsole.log("SupertabMonetizationProvider: monetize", { response });
        return response;
      }
      default:
        throw new Error(
          `Unexpected monetizationPortal value: ${monetizeParams.monetizationPortal}`
        );
    }
  }

  private userAccessStatusToUserEntitlementState(
    userAccessStatus: UserAccessStatus
  ): UserEntitlementStateEnumDefaultType {
    switch (userAccessStatus) {
      case UserAccessStatus.ACCESS_GRANTED:
        return window.googlefc.monetization.UserEntitlementStateEnum
          .ENTITLED_YES;
      case UserAccessStatus.ACCESS_DENIED:
        return window.googlefc.monetization.UserEntitlementStateEnum
          .ENTITLED_NO;
      default:
        return window.googlefc.monetization.UserEntitlementStateEnum
          .ENTITLED_UNKNOWN;
    }
  }

  private backToCallerEventDetailToMonetizeResponse({
    accessStatus,
    accessDetails,
    accessValidTo,
    itemAdded,
    tabPaid,
    currencyDetail,
  }: BackToCallerEventDetail): MonetizeResponse {
    const userEntitlementState =
      this.userAccessStatusToUserEntitlementState(accessStatus);
    const response: MonetizeResponse = { userEntitlementState };
    if (itemAdded) {
      response.additionalMonetizeResponseData = {
        newlyPledgedAmountByUser: priceToMoney({
          amount: itemAdded.price.amount,
          currency: itemAdded.price.currency,
          currencyDetail,
        }),
      };
    }
    if (itemAdded?.timePassDetails) {
      response.newlyGrantedUserEntitlementType =
        window.googlefc.monetization.EntitlementTypeEnum.TYPE_DURATION_SECONDS;
      response.newlyGrantedUserEntitlementValue = this.timeDeltaStringToSeconds(
        itemAdded.timePassDetails.validTimedelta!
      );
    }
    if (tabPaid) {
      response.newlyPaidAmountByUser = priceToMoney({
        amount: tabPaid.total,
        currency: tabPaid.currency,
        currencyDetail,
      });
    }
    return response;
  }

  private timeDeltaStringToSeconds(deltaString: string): number {
    const amount = parseInt(deltaString);
    const unit = deltaString.slice(-1);
    const factor = {
      s: 1,
      m: 60,
      h: 60 * 60,
      d: 60 * 60 * 24,
      w: 60 * 60 * 24 * 7,
      M: 60 * 60 * 24 * 7 * 30,
      y: 60 * 60 * 24 * 7 * 365,
    }[unit];
    if (Number.isNaN(amount) || !factor) {
      console.error({ deltaString, amount, unit, factor });
      throw new Error(`Failed to parse time delta: ${deltaString}`);
    }
    return amount * factor;
  }

  destroy(destroyParams: DestroyParams): void {
    cowConsole.log("SupertabMonetizationProvider: destroy", { destroyParams });
    this.widget?.destroy();
  }

  private assertWidget() {
    if (!this.widget) {
      throw new Error(
        "SupertabMonetizationProvider: `widget` property is required, call `#initialize` first."
      );
    }
    return this.widget;
  }
}

function priceToMoney(price: {
  amount: number;
  currency: string;
  currencyDetail?: {
    isoCode: string;
    baseUnit: number;
  };
}): Money {
  if (!price.currencyDetail) {
    throw new Error(
      'Failed to create "Money" object due to missing currencyDetail'
    );
  }
  if (price.currency !== price.currencyDetail.isoCode) {
    throw new Error(
      `Failed to create "Money" object due to currency mismatch. Expected "${price.currency}" from price and "${price.currencyDetail.isoCode}" from currency detail to be equal.`
    );
  }
  const displayedAmount = price.amount / price.currencyDetail.baseUnit;
  const units = Math.floor(displayedAmount);
  const nanos = (displayedAmount - units) * 1000000000; // 10^9
  return {
    units,
    nanos,
    currencyCode: price.currency,
  };
}

export default SupertabMonetizationProvider;
