import { decodeJwt } from "../../util/jwt";
import { timestampInMilliseconds } from "../../util/misc";
import { AuthenticationMachineContext } from "./types";

export const BEFORE_ACCESS_TOKEN_EXPIRATION = (
  ctx: AuthenticationMachineContext
) => {
  if (!ctx.accessToken) {
    return 0;
  }

  const token = decodeJwt(ctx.accessToken);
  const exp = timestampInMilliseconds(token.exp);
  const diff = exp - Date.now();
  // Subtract 1 minute to make sure we refresh the token before it expires
  const delay = diff - 1000 * 60 > 0 ? diff - 1000 * 60 : 1000;

  return delay;
};
