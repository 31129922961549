import styled from "@emotion/styled";
import { Colors, TextTiny } from "../../../styles/GlobalStyles";
import { CurrencySymbol } from "@getsupertab/price-utils";

export const Text = styled(CurrencySymbol)`
  color: ${Colors.textGray};
  display: block;
  line-height: 1.3;
  ${TextTiny};
`;
