import { sendParent, assign, actions, ActionFunction } from "xstate";
import {
  AuthenticationMachineContext,
  AuthenticationMachineEvents,
} from "./types";
import { sendErrorToSentry } from "../../../capture-errors";

import { StorageKeys } from "../../../tapper-browser-client";

export const trackError: ActionFunction<
  AuthenticationMachineContext,
  AuthenticationMachineEvents,
  AuthenticationMachineEvents
> = (_, ev: any) => {
  console.error(ev);
  if (ev.data) {
    sendErrorToSentry(ev.data);
  }
};

export const sendLoggedIn = sendParent<
  AuthenticationMachineContext,
  AuthenticationMachineEvents,
  AuthenticationMachineEvents
>((ctx, evt) => ({
  type: "LOGGED_IN",
  user: evt.type === "done.invoke.identifyUser" ? evt.data : undefined,
  accessToken: ctx.accessToken,
  refreshToken: ctx.refreshToken,
}));

export const sendRefreshedTokens = sendParent<
  AuthenticationMachineContext,
  AuthenticationMachineEvents,
  AuthenticationMachineEvents
>((ctx) => ({
  type: "REFRESHED_TOKENS",
  accessToken: ctx.accessToken,
  refreshToken: ctx.refreshToken,
}));

export const sendLoggedOut = sendParent<
  AuthenticationMachineContext,
  AuthenticationMachineEvents,
  AuthenticationMachineEvents
>({ type: "LOGGED_OUT" });

export const sendAuthFailed = actions.pure<
  AuthenticationMachineContext,
  AuthenticationMachineEvents,
  AuthenticationMachineEvents
>((ctx) =>
  sendParent({
    type: "AUTH_FAILED",
    userWasLoggedIn: !!(ctx.accessToken && ctx.refreshToken),
  })
);

export const closeAuthWindow = () => {
  window.open(undefined, "ssoWindow")?.close();
};

export const loadTokens = assign<
  AuthenticationMachineContext,
  AuthenticationMachineEvents
>(() => ({
  accessToken: localStorage.getItem(StorageKeys.AccessToken) ?? undefined,
  refreshToken: localStorage.getItem(StorageKeys.RefreshToken) ?? undefined,
  authCode: sessionStorage.getItem(StorageKeys.AuthCode) ?? undefined,
  codeVerifier: sessionStorage.getItem(StorageKeys.CodeVerifier) ?? undefined,
}));

export const saveTokens = assign<
  AuthenticationMachineContext,
  AuthenticationMachineEvents,
  AuthenticationMachineEvents
>((_, evt) => {
  if (evt.type === "done.invoke.authorizeClient") {
    sessionStorage.setItem(StorageKeys.AuthCode, evt.data.authCode);
    sessionStorage.setItem(StorageKeys.CodeVerifier, evt.data.codeVerifier);
    return {
      codeVerifier: evt.data.codeVerifier,
      authCode: evt.data.authCode,
    };
  }

  if (
    evt.type === "done.invoke.fetchTokens" ||
    evt.type === "done.invoke.refreshTokens"
  ) {
    localStorage.setItem(StorageKeys.AccessToken, evt.data.accessToken);
    localStorage.setItem(StorageKeys.RefreshToken, evt.data.refreshToken);

    return {
      accessToken: evt.data.accessToken,
      refreshToken: evt.data.refreshToken,
    };
  }

  if (evt.type === "done.invoke.identifyUser") {
    return { user: evt.data };
  }

  return {};
});

export const removeTokens = assign<
  AuthenticationMachineContext,
  AuthenticationMachineEvents,
  AuthenticationMachineEvents
>(() => {
  sessionStorage.removeItem(StorageKeys.AuthCode);
  sessionStorage.removeItem(StorageKeys.CodeVerifier);
  localStorage.removeItem(StorageKeys.AccessToken);
  localStorage.removeItem(StorageKeys.RefreshToken);

  return {
    codeVerifier: undefined,
    authCode: undefined,
    accessToken: undefined,
    refreshToken: undefined,
    user: undefined,
  };
});
export const sendAuthEntryEvents = actions.pure<
  AuthenticationMachineContext,
  AuthenticationMachineEvents,
  AuthenticationMachineEvents
>((_, evt) => {
  if (evt.type === "START_AUTH") {
    return sendParent({
      type: "AUTH_WINDOW_OPENED",
      screenHint: evt.screenHint,
      loginOption: evt.loginAction,
    });
  }
});

export const sendAuthExitEvents = actions.pure<
  AuthenticationMachineContext,
  AuthenticationMachineEvents,
  AuthenticationMachineEvents
>((_, evt) => {
  if (evt.type === "done.invoke.identifyUser") {
    const createdAt = new Date(evt.data.createdAt);
    const accountAge = new Date().getTime() - createdAt.getTime();

    return sendParent({
      type: "AUTH_WINDOW_DONE",
      user: evt.data,
      isNewUser: accountAge < 1000 * 10, //< 10 seconds ago
    });
  } else if (evt.type === "error.platform.authorizeClient") {
    return sendParent({
      type: "AUTH_WINDOW_FAILED",
      screenHint: evt.data.screenHint,
      loginOption: evt.data.loginAction,
    });
  } else {
    return sendParent({
      type: "AUTH_WINDOW_FAILED",
    });
  }
});
