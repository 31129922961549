import React, { FC, useState } from "react";
import useRemainingTime from "../../../hooks/useRemainingTime";
import SupertabLogo from "../../atoms/SupertabLogo";
import Close from "../Close";
import { InnerTrigger, Timer, WidgetWrapper } from "./styled";
import { ReactComponent as Clock } from "../../../../svg/clock.svg";
import { isMobile } from "../../../util/misc";

interface WidgetTriggerProps {
  onClick: () => void;
  validTo: Date | undefined;
  isOpen: boolean;
  accessTime: number;
  isThankyouOpen: boolean;
}

const WidgetTrigger: FC<WidgetTriggerProps> = ({
  onClick,
  validTo,
  isOpen,
  accessTime,
  isThankyouOpen,
}) => {
  const { remainingTime } = useRemainingTime(validTo, accessTime);
  const [showTimer, setShowTimer] = useState(false);
  const isMobileBrowser = isMobile.any();
  const hasTimerActive =
    showTimer &&
    !!remainingTime[0]?.time &&
    !isMobileBrowser &&
    !isThankyouOpen &&
    !isOpen;

  return (
    <WidgetWrapper
      onMouseOver={() => !!remainingTime[0]?.time && setShowTimer(true)}
      onMouseOut={() => !!remainingTime[0]?.time && setShowTimer(false)}
      hasTimer={!!remainingTime.length && !isOpen}
      data-testid="lbw-trigger"
      onClick={onClick}
    >
      {!isMobileBrowser && <InnerTrigger hasTimer={hasTimerActive} />}
      {isOpen ? (
        <Close />
      ) : (
        <>
          {hasTimerActive ? (
            <Timer>
              <Clock />
              {remainingTime.map((item) => (
                <div key={item.label} data-testid="remaining-time">
                  <span>
                    {item.time}
                    {item.label}
                  </span>
                </div>
              ))}
            </Timer>
          ) : (
            <SupertabLogo />
          )}
        </>
      )}
    </WidgetWrapper>
  );
};

export default WidgetTrigger;
