import styled from "@emotion/styled";
import { ColorWayStyleProps } from "../../../styles/types";
import { Colors } from "../../../styles/GlobalStyles";

export const Container = styled.span<ColorWayStyleProps>`
  line-height: 15px;

  &[data-weight="medium"] {
    font-weight: 500;
  }
  &[data-weight="bold"] {
    font-weight: 700;
  }

  color: ${(props) =>
    props.colorWay ? Colors[props.colorWay] : Colors.primary};
`;
